import React, {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import {
  Button,
  Divider,
  Fade,
  IconButton,
  Stack,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@mui/material";
import Box from "@mui/material/Box";
import {IconPhoneOutgoing, IconX} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import Typography from "@mui/material/Typography";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {xs: "80vw", sm: "70vw", md: "50vw", lg: "30vw"},
  maxWidth: "80vw",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};


export const SIPModal = ({open, handleClose, project, onUpdate}) => {
  const [newProject, setNewProject] = useState(null);

  useEffect(() => {
    if (open && project) {
      console.log(project?.settings);
      setNewProject({...project});
    }
  }, [open, project])

  const onCreate = () => {
    onUpdate(newProject);
    onClose();
  }

  const onClose = () => {
    setNewProject(null);
    handleClose();
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={onClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>

          <Stack direction={"column"} gap={2} sx={style}>
            <Stack direction={"row"} gap={1}>
              <IconPhoneOutgoing />
              <Typography variant={"h4"} fontWeight={"bold"} sx={{mb: 1}}>Voice Connector Configuration</Typography>
            </Stack>
            <Divider />
            <TextField
              label={"Host"}
              size={"small"}
              value={newProject?.settings?.host || ""}
              placeholder={"ex: myhost.com"}
              onChange={e => {
                setNewProject(prev => {
                  const state = {...prev};
                  if (!state.settings) {
                    state.settings = {}
                  }
                  state.settings.host = e.target.value;
                  return state;
                })
              }}
            />
            <TextField
              label={"Port"}
              size={"small"}
              value={newProject?.settings?.port || ""}
              placeholder={"ex: 5050"}
              onChange={e => {
                setNewProject(prev => {
                  const state = {...prev};
                  if (!state.settings) {
                    state.settings = {}
                  }
                  state.settings.port = e.target.value;
                  return state;
                })
              }}
              helperText={"Must be between 1 and 65535"}
            />
            <FormControl size={"small"}>
              <InputLabel id={`sip-label`}
                          sx={{textTransform: "capitalize"}}>Protocol</InputLabel>
              <Select
                labelId={`sip-label`}
                id={`sip-select`}
                label={"Protocol"}
                value={newProject?.settings?.protocol || ""}
                onChange={e => {
                  setNewProject(prev => {
                    const state = {...prev};
                    state.settings.protocol = e.target.value;
                    return state;
                  })
                }}
              >
                <MenuItem value={""}><em>None</em></MenuItem>
                <MenuItem value={"TCP"}>TCP</MenuItem>
                <MenuItem value={"UDP"}>UDP</MenuItem>
              </Select>
            </FormControl>
            <Divider/>
            <Stack direction={'row'} gap={1} justifyContent={"space-between"} alignItems={"center"}
                   sx={{mt: "auto"}}>
              <Stack direction={'row'} gap={1}>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  sx={{borderRadius: 0, textTransform: "capitalize"}}
                  onClick={onCreate}
                >
                  Save
                </Button>
                <Button
                  variant={"outlined"}
                  color={"secondary"}
                  sx={{borderRadius: 0, textTransform: "capitalize"}}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}