import React, {useContext, useEffect, useRef, useState} from "react";
import {
  Box,
  Button,
  Checkbox, Divider,
  Fade,
  FormControlLabel,
  Grid,
  Stack,
  TextField, Toolbar,
  Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {ArrowForwardIos} from "@mui/icons-material";
import {toast} from "react-toastify";
import {SignInContext} from "../../../context/SignInContext";
import {IconBrandGoogle} from "@tabler/icons-react";

export const LoginPage = () => {
  const [loginWithMail, setLoginWithMail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const passwordRef = useRef(null);
  const {onLogin} = useContext(SignInContext);

  useEffect(() => {
    if (loginWithMail) {
      passwordRef.current?.focus();
    }
  }, [loginWithMail])


  const loginHandle = async (e) => {
    e.preventDefault();
    if (!loginWithMail) {
      setLoginWithMail(true);
      return;
    }
    try {
      await onLogin({username: email, password: password});
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Grid container sx={{width: "100%", height: "100%", bgcolor: "rgba(0,0,0, 0.03)"}}>
      <Grid item gap={2} xs={12} md={6} lg={5} justifyContent={"space-between"}
            sx={{display: "flex", flexDirection: "column", height: "100%"}}>
        <Toolbar>
          <img src={"/logo.svg"} alt={"logo"} width={30}/>
        </Toolbar>
        <Stack
          maxWidth={"sm"} direction={"column"} gap={3}
          sx={{maxWidth: "400px", width: "100%", p: 2, flex: 1, mx: "auto"}}
          justifyContent={'center'} alignItems={"center"}
        >
          {/*HEADER*/}
          <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
            {/*TITLE*/}
            <Typography color={"dark.main"} fontSize={50} variant={'h2'} textAlign={"center"}
                        fontWeight={"bold"}>Sign In</Typography>
            {/*SIGN UP*/}

            <Box sx={{textAlign: "center"}}>
              <Typography sx={{color: grey[700]}} component={"span"}>Don't have account
                yet?</Typography>
              <Link to={"/register"} style={{
                paddingLeft: 5,
                color: "#252525",
                fontWeight: 'bold',
                textDecoration: "none",
                cursor: "pointer"
              }}>
                Sign up
              </Link>
            </Box>
          </Stack>
          {/*LOGIN WITH MAIL*/}
          <Stack component={"form"} onSubmit={loginHandle} direction={"column"} gap={1} sx={{width: '100%'}}>
            <TextField
              variant={"standard"}
              required
              fullWidth
              label={"Email"}
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={"Enter your email address"}
            />
            {
              loginWithMail &&
              <Fade
                in={loginWithMail}
              >
                <TextField
                  ref={passwordRef}
                  autoFocus
                  type={"password"}
                  variant={"standard"}
                  required
                  fullWidth
                  label={"Password"}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder={"Password"}
                />
              </Fade>
            }

            <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"}
                   useFlexGap flexWrap={"wrap"}>
              <FormControlLabel
                sx={{color: grey[700]}}
                control={(
                  <Checkbox defaultChecked
                            sx={{"&.Mui-checked": {color: "#8491F7"}}}/>
                )}
                label="Remember me"
              />
              {/*<Typography sx={{textDecoration: "none", color: grey[700]}} component={Link}*/}
              {/*            to={"/forgot-password"}>Forgot password?</Typography>*/}
            </Stack>
            {
              loginWithMail ?
                <Button
                  fullWidth
                  type={"submit"}
                  variant={"contained"}
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  endIcon={<ArrowForwardIos style={{fontSize: 13}}/>}
                >
                  Log in
                </Button>
                :
                <Button
                  fullWidth
                  type={"submit"}
                  variant={"contained"}
                  disabled={!email}
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  endIcon={<ArrowForwardIos style={{fontSize: 13}}/>}
                >
                  Continue
                </Button>
            }

            <Divider>OR</Divider>
            <Box>
              <Button
                component={'a'}
                href={`${process.env.REACT_APP_USERMANAGEMENT_GATEWAY}/oauth2/authorization/google?redirect_uri=${process.env.REACT_APP_GATEWAY}/oauth2/redirect`}
                fullWidth disabled
                size="large"
                variant="outlined"
                color="secondary"
                startIcon={<IconBrandGoogle/>}
              >
                Google
              </Button>
            </Box>

          </Stack>
        </Stack>
        <Toolbar sx={{justifyContent: "space-between"}}>
          <img src={"/logo-black.svg"} alt={"logo"} width={60}/>
          <a href={"https://openbrain.io"} target={"_self"}
             style={{fontSize: 12, textDecoration: "none", color: "#252525"}}>openbrain.io</a>
        </Toolbar>
      </Grid>


      <Grid item gap={2} xs={12} md={6} lg={7} alignItems={"center"} justifyContent={"center"}
            sx={{display: {xs: "none", md: "flex"}, bgcolor: "primary.main"}}>
        <Stack sx={{maxWidth: "340px", textAlign: "center"}} direction={"column"} alignItems={"center"}
               justifyContent={"center"}>
          <img src={"/logo_white.svg"} alt={"logo"} width={300} style={{margin: "auto"}}/>
          <Typography fontSize={30} fontWeight={700} color={"#EAEAEA"}>OpenBrain</Typography>
          <Typography fontSize={16} fontWeight={500} color={"#EAEAEA"}>
            Build your VoiceBot Assistant in minutes.
            {/*Create your voice bot assistant effortlessly with BlockFlow Chat.*/}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}