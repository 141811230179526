import {Box, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers";
import {ASRIConversationsRepository} from "../../../../repositories/ASRIConversationsRepository";
import useWebSocket from "../../../../hooks/useWebSocket";
import {VdnNames} from "../../../../utils/VdnNames";


export const VDNRedirectionStatistics = ({title, project}) => {
  const dateLastMonth = new Date();
  const dateNow = new Date()
  dateLastMonth.setTime(dateNow.setHours(0, 0, 0, 0))
  dateNow.setTime(dateNow.setHours(0, 0, 0, 0))
  const updatedValue = useWebSocket(`/topic/active_calls/${project?.settings?.speechToText}`);

  const [vdnStatistics, setVDNStatistics] = useState(null);
  const [chartData, setChartData] = useState({"options": {}, "seriesData": []})
  const [startDate, setStartDate] = useState(moment(dateLastMonth.setMonth(dateNow.getMonth() - 1)));
  const [endDate, setEndDate] = useState(moment(dateNow));
  const [seriesData, setSeriesData] = useState([])

  // Function to prepare chart data with multiple series based on status
  const prepareChartData = (groupedData) => {
    // const groupedData = groupByDateAndStatus(data, dateFormat, view);
    const cats = Object.keys(groupedData).sort((a, b) => {
      // Parsing the dates with Moment.js
      const dateA = moment(a, 'YYYY-MM-DD');
      const dateB = moment(b, 'YYYY-MM-DD');

      // Sorting the dates
      return dateA.isAfter(dateB) ? 1 : -1;
    });
    console.log("cats:" + cats)
    const allVDNResults = Object.values(groupedData).flat();
    const vdns = [...new Set(allVDNResults.map(result => result.vdn))];
    // const vdns = Array.from(new Set(Object.keys(groupedData).map(t => groupedData[t].vdn)));

    const data = vdns.map(vdn => {
      const name = VdnNames[vdn];
      return {
        name: name ? `${name} - ${vdn}` : vdn || "unknown",
        data: cats.map(date => groupedData[date].find(result => result.vdn === vdn)?.count || 0),
      };
    });
    console.log(data);
    setSeriesData(data)

    return {
      seriesData: [],
      chart: {
        type: "bar",
        stacked: true
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0
      },
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 200
              }
            }
          }
        },
      }, stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: cats,
        title: "Number of Calls",
        labels: {
          formatter: function (val) {
            return moment(val).format("DD MMM yyyy")
          }
        }
      },
      yaxis: {
        title: "Date",
        labels: {
          formatter: function (val) {
            return val
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val

          }
        }
      },
      fill: {
        opacity: 1
      }
    }
  };

  const fetchData = () => {
    const sessionId = project?.settings?.speechToText;
    const start = startDate.format('YYYY-MM-DDT00:00:00');
    const end = endDate === null ? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') : endDate.format('YYYY-MM-DDT23:59:59')
    ASRIConversationsRepository.getVDNRedirectsBetweenDates({sessionId, startDate: start, endDate: end})
      .then(response => {
        setChartData(prepareChartData(response.data))
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    fetchData()
  }, [project, startDate, endDate]);


  return (
    <Stack direction={"column"} gap={1} sx={{p: {xs: 2, md: 4}, borderRadius: 2}} className={"border-shadow"}>
      <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"flex-end"} useFlexGap
             flexWrap={"wrap"}>
        <Box>
          <Typography variant={"h5"} fontWeight={600}>Transferred Calls per VDN</Typography>
        </Box>
        <Stack direction={"row"} gap={2}>
          <DatePicker
            format={"DD MMM YYYY"} label={"From"}
            value={startDate} onChange={value => setStartDate(value)}
            slotProps={{
              textField: {
                variant: "standard", size: "small"
              }
            }}
          />
          <DatePicker
            format={"DD MMM YYYY"} label={"To"}
            value={endDate} onChange={value => setEndDate(value)}
            slotProps={{
              textField: {
                variant: "standard", size: "small"
              }
            }}
          />
        </Stack>
      </Stack>
      <Chart options={chartData} series={seriesData} type="bar" height={300}/>
    </Stack>
  );
};
