import {
  Box,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import {IconChevronLeft, IconChevronRight, IconDatabaseImport, IconFileUpload, IconUser} from "@tabler/icons-react";
import React, {useState} from "react";
import {ProjectForm} from "./ProjectForm";
import {ProjectCreationType} from "../ProjectCreationPage";
import {SelectTemplate} from "./SelectTemplate";

export const ProjectCreateForm = ({project, setProject, type, setType, languages, organizations, asriProjects, fetchData, loading}) => {
  const [step, setStep] = useState(0);

  return (
    <>
      <Stack direction={"row"} gap={0} sx={{px: 2, py: 1, borderBottom: 1, borderColor: "divider"}}>
        <Button color={type === ProjectCreationType.NEW_ASSISTANT ? "primary" : "secondary"}
                onClick={() => setType(ProjectCreationType.NEW_ASSISTANT)}
                startIcon={<IconUser/>}
        >
          New Assistant
        </Button>
        <Button color={type === ProjectCreationType.IMPORT_ASSISTANT ? "primary" : "secondary"}
                onClick={() => setType(ProjectCreationType.IMPORT_ASSISTANT)} disabled
                startIcon={<IconDatabaseImport/>}
        >
          Import Assistant
        </Button>
      </Stack>
      {
        {
          0: (
            <>
              <ProjectForm setProject={setProject} project={project} languages={languages} organizations={organizations} asriProjects={asriProjects} fetchData={fetchData}/>
              <Box sx={{px: 3, pb: 2, textAlign: "right"}}>
                <Button type={"submit"} variant={"contained"}
                        disabled={!project?.name || !project?.language || !project?.organization || !project?.speechToText || loading}
                        sx={{borderRadius: "8px !important"}}
                        endIcon={loading ? <CircularProgress size={18} color={"secondary"} /> : <IconChevronRight size={18}/>}>Submit</Button>
              </Box>
            </>
          ),
          1: (
            <>
              <SelectTemplate
                project={project} setProject={setProject}
              />

              <Stack direction={"row"} gap={2} justifyContent={"space-between"} sx={{px: 3, pb: 2, textAlign: "right"}}>
                <Button type={"button"} color={"secondary"} onClick={() => setStep(step - 1)} variant={"text"}
                        sx={{borderRadius: "8px !important"}} startIcon={<IconChevronLeft size={18}/>}>Back</Button>
                <Button variant={"contained"} type={"submit"} sx={{borderRadius: "8px !important"}}
                        disabled={!project?.file || loading}
                        endIcon={loading ? <CircularProgress size={18} color={"secondary"} /> : <IconChevronRight size={18}/>}>Submit</Button>
              </Stack>
            </>
          )
        }[step]
      }

    </>
  )
}