import React, {useContext, useEffect, useMemo, useRef} from "react";
import {Box, Stack} from "@mui/material";
import {Header} from "./header/Header";
import "./main.css"
import {WebSettingsContext} from "../../context/WebSettingsContext";
import {SignInContext} from "../../context/SignInContext";
import {useNavigate} from "react-router-dom";
import {LeftMainMenu} from "../../pages/project/flow/side-menu/LeftMainMenu";
import {ChatDrawer} from "./header/ChatDrawer";

export const FlowLayout = ({project, flow, node, children, openChat, toggleChat}) => {
    const {settings} = useContext(WebSettingsContext);
    const {currentUser} = useContext(SignInContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser !== null) {
            const isAdmin = currentUser?.roles?.includes("admin")

            if (!isAdmin && settings?.underMaintenance) {
                navigate("/under-maintenance");
            }
        }
    }, [settings, currentUser, navigate])

    const mainRef = useRef(null);

    const height = useMemo(() => {
        if (mainRef.current) {
            return `${mainRef.current.offsetHeight}px`;
        }

        return '100%';
    }, [mainRef.current])

    return (
        <Stack direction={"column"} sx={{width: "100vw", height: "100vh", bgcolor: 'transparent'}}>
            <Header project={project} flow={flow} node={node} openChat={openChat} toggleChat={toggleChat}/>
            <Box ref={mainRef} sx={{flex: 1, display: "flex", flexDirection: "row"}}>
                <LeftMainMenu
                    project={project}
                />
                <Box sx={{flex: 1, maxHeight: height, height: "100%", display: "flex", flexDirection: "column"}}>
                    {children}
                </Box>
                <ChatDrawer

                    open={openChat}
                    handleClose={toggleChat}
                    project={project}
                />

            </Box>

        </Stack>
    )
}