import {
  Avatar,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu, MenuItem,
  Stack,
  Tooltip, Typography
} from "@mui/material";
import {
  IconLayersLinked,
  IconDatabase,
  IconSettings,
  IconAdjustmentsCog,
  IconMessage2,
  IconPlugConnected, IconUser, IconBulldozer, IconLogout, IconUserCog, IconChartBar
} from '@tabler/icons-react'
import React, {useContext, useState} from "react";
import {Context as AppContext} from "../../../../context/AppContext";
import {SettingsModal} from "../../../../components/settings/SettingsModal";
import {toast} from "react-toastify";
import {SIPModal} from "../../../../components/SIP/SIPModal";
import {ExportProjectButton} from "../../../../layouts/flow-layout/header/Header";
import {NavLink} from "react-router-dom";
import {WebSettingsContext} from "../../../../context/WebSettingsContext";
import { IconDashboard } from '@tabler/icons-react';
import {grey} from "@mui/material/colors";
import {SignInContext} from "../../../../context/SignInContext";

export const LeftMainMenu = ({project}) => {
  const {updateProject} = useContext(AppContext)
  const {currentUser, onLogout} = useContext(SignInContext);

  const [openSettings, setOpenSettings] = useState(false);
  const [openSIP, setOpenSIP] = useState(false);

  const onUpdateProject = async (newProject) => {
    try {
      await updateProject(newProject);
      toast.success("Project updated successfully");
    } catch (err) {
      console.log(err);
      toast.error("Failed to update project")
    }
  }

  console.log("ASRI", !project?.settings?.speechToText)

  return (

    <>
      <SettingsModal
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
        project={project}
        onUpdate={onUpdateProject}
      />

      <SIPModal
        open={openSIP}
        disabled={!project}
        handleClose={() => setOpenSIP(false)}
        project={project}
        onUpdate={onUpdateProject}
      />

      <Stack direction={"column"} gap={2} sx={{
        maxHeight: '100%',
        minWidth: "48px",
        maxWidth: "48px",
        width: "100%",
        borderRight: 1,
        pb: 0.5,
        pt: 1,
        px: 0.5,
        borderColor: "divider",
        // backgroundColor: "#f1f4f7",
        overflowY: "auto",
      }}>
        <Tooltip title={"Dashboard"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} end to={`/project/${project?.id}`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconDashboard  stroke={1.5}/>
          </Button>
        </Tooltip>
        <Tooltip title={"Designer"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} end to={`/project/${project?.id}/designer`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconLayersLinked stroke={1.5}/>
          </Button>
        </Tooltip>

        <Tooltip title={"Conversation Logger"} placement={"right"}>
          <Button component={NavLink} disabled={!project?.settings?.speechToText} className={"side-link"}
                  to={`/project/${project?.id}/asri`}
                  sx={{minWidth: 40}}>
            <IconMessage2 stroke={1.5}/>
          </Button>
        </Tooltip>
        <Tooltip title={"Voice Connector"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} to={`/project/${project?.id}/sip`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconPlugConnected stroke={1.5}/>
          </Button>
        </Tooltip>

        <Tooltip title={"Settings"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} to={`/project/${project?.id}/settings`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconSettings stroke={1.5}/>
          </Button>
        </Tooltip>

        <UserAvatar currentUser={currentUser} onLogout={onLogout}/>

      </Stack>
    </>
  )
}

export const UserAvatar = ({currentUser, onLogout, theme}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const isAdmin = currentUser?.roles?.includes("admin")
  const {settings, onUnderMaintenance} = useContext(WebSettingsContext);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutHandle = async () => {
    try {
      await onLogout();
      handleCloseUserMenu();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Box sx={{flexGrow: 0, mt: "auto"}}>
      <Tooltip title={"Settings"} placement={"right"}>
        <Button className={"side-link"}
                onClick={handleOpenUserMenu}
                sx={{minWidth: 40, color: "primary.dark"}}>
          <IconUserCog stroke={1.5}/>
        </Button>
      </Tooltip>
      <Menu
        sx={{p: 0, borderRadius: 0, minWidth: "280px"}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{sx: {borderRadius: 4, minWidth: "200px"}}}
        PaperProps={{sx: {borderRadius: 4, minWidth: "200px"}}}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}
               sx={{px: 2, py: 1, borderBottom: 1, borderColor: "divider"}}>
          <Avatar sx={{width: "35px", height: '35px', bgcolor: grey[100]}}><IconUser color={grey[600]}
                                                                                     size={20}/></Avatar>
          <Typography fontWeight={"bold"}>{currentUser?.name || currentUser?.lastname ? `${currentUser?.name} ${currentUser?.lastname}` : currentUser?.username}</Typography>
        </Stack>
        {
          isAdmin &&
          <MenuItem onClick={onUnderMaintenance}
                    color={settings?.underMaintenance ? "error" : "primary"}
                    sx={{borderBottom: 1, borderColor: "divider", px: 3, py: 1, fontSize: 16}}>
            <ListItemIcon>
              <IconBulldozer/>
            </ListItemIcon>
            <ListItemText primary={"Under Maintenance"}/>
          </MenuItem>
        }
        <MenuItem onClick={logoutHandle}
                  sx={{px: 3, py: 1, fontSize: 16}}>
          <ListItemIcon>
            <IconLogout/>
          </ListItemIcon>
          <ListItemText primary={"Logout"}/>
        </MenuItem>
      </Menu>
    </Box>
  )
}
