import {IconDeviceSpeaker, IconPhoneCall, IconRobot, IconWaveSine} from "@tabler/icons-react";
import {DashboardCard} from "./DashboardCard";

export const SynthesisResponseTime = ({responseTime, conversations}) => {
  return (
    <DashboardCard
      count={responseTime > 0 ? (responseTime / 1000)?.toFixed(2) : 0}
      title={"TTS (s)"}
      icon={IconWaveSine}
      color={"#3d55a2"}
      description={"RESPONSE TIME"}
      chartSeries={conversations?.map(item => ({id: item?.conversationId, dateCreated: item?.dateCreated, value: (item?.averageValue / 1000)?.toFixed(2)})) || []}
    />
  );
}

