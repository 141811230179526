import {useCallback, useEffect, useState} from "react";
import {messages} from "./conversations";
import {Stack} from "@mui/material";
import ReactFlow, {addEdge, ConnectionLineType, useEdgesState, useNodesState} from "reactflow";
import {getLayoutedElements} from "./react-flow-sort";
import StartNode from "./nodes/StartNode";
import TextNode from "./nodes/TextNode";


const nodeTypes = {
  start: StartNode,
  text: TextNode
};

export const ConversationBuilder = () => {
  const [nodeIds, setNodeIds] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {...params, type: ConnectionLineType.SmoothStep, animated: true},
          eds,
        ),
      ),
    [],
  );
  const onLayout = useCallback(
    ({direction, nodes, edges}) => {
      const {nodes: layoutedNodes, edges: layoutedEdges} =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges],
  );


  useEffect(() => {
    mapConversation(messages);
  }, [])

  const mapConversation = (messages) => {
    const nodeIds = {};
    const newEdges = [];
    let prevId = null;
    for (const message of messages) {
      const nodeId = message?.nodeId;
      let node = {
        id: nodeId,
        data: {messages: [], activeCalls: ["123123", "23123123", "451351234", "3311123", "231451141"]},
        type: prevId ? "text" : "start",
        sourcePosition: "right"
      };
      if (nodeIds[nodeId]) {
        node = {...nodeIds[nodeId]}
      }
      if (!node?.data?.messages?.includes(message?.text)) {
        node?.data?.messages?.push(message?.text);
      }
      nodeIds[nodeId] = node;

      if (prevId && prevId !== nodeId) {
        const edge = {
          id: `e${prevId}-${nodeId}`,
          source: prevId,
          target: nodeId,
          animated: true,
          style: {stroke: '#b6b3b3'},
        }
        newEdges?.push(edge);
      }

      prevId = nodeId;
    }
    const newIds = newEdges?.map(i => i?.id);
    const state = [...edges]?.filter(i => !newIds?.includes(i?.id));
    const updatedEdges = [...state, ...newEdges];

    const newNodes = Object.values(nodeIds);
    const newNodeIds = newNodes?.map(i => i?.id);
    const nodesState = [...nodes]?.filter(i => !newNodeIds?.includes(i?.id));
    const updatedNodes = [...nodesState, ...newNodes];

    onLayout({direction: "LR", nodes: updatedNodes, edges: updatedEdges});
    setNodeIds(nodeIds);
  }

  return (
    <Stack direction={"row"} sx={{maxWidth: "100%", maxHeight: "100%"}}>
      <Stack direction={"column"} sx={{flex: 1, overflowY: "auto"}}>

        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          style={{background: "#fefefe99"}}
          nodeTypes={nodeTypes}
          connectionLineStyle={ConnectionLineType.SmoothStep}
          fitView
          attributionPosition="bottom-left"
        >
        </ReactFlow>

      </Stack>
      <Stack direction={"column"} gap={2}
             sx={{
               minWidth: "280px",
               maxWidth: "480px",
               width: "100%",
               overflowY: "auto",
               border: 1,
               borderColor: "divider"
             }}>
            <pre>
                {JSON.stringify(nodes, null, 4)}
            </pre>
      </Stack>

    </Stack>
  )
}