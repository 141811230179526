import {toast} from "react-toastify";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React from "react";

export const CreateVocabularyItem = ({open, handleClose, selectedItem, organization}) => {

  const handleDelete = async () => {
    try {
      await fetch(`${process.env.REACT_APP_JSON_SERVER}/vocabulary/${selectedItem?.id}`, {
        method: "DELETE"
      })
      toast.success("Successfully deleted");
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete!");
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {borderRadius: 5, p: 2},
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData).entries());
          formJson.organizationId = organization;
          formJson.updatedAt = new Date().getTime();

          if (!formJson.key || !formJson.value) {
            toast.error("Name is required field")
            return;
          }
          try {
            if (selectedItem && selectedItem?.id) {
              await fetch(`${process.env.REACT_APP_JSON_SERVER}/vocabulary/${selectedItem?.id}`, {
                method: "PUT",
                body: JSON.stringify(formJson)
              })
            } else {
              formJson.createdAt = new Date().getTime();
              await fetch(`${process.env.REACT_APP_JSON_SERVER}/vocabulary`, {
                method: "POST",
                body: JSON.stringify(formJson)
              })
            }
            handleClose();

          } catch (error) {
            console.log(error);
            toast.error("Failed to update vocabulary!");
          }

        },
      }}
    >
      <DialogTitle>{selectedItem ? "Update" : "Add new"} vocabulary item</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={12}>
          I models may not always know how to read or pronounce every word correctly, especially if the word is uncommon, technical, or in a different language. By adding vocabulary, you can provide the AI with the correct pronunciation or reading of the word.
        </DialogContentText>
        <TextField
          autoFocus
          required
          defaultValue={selectedItem?.key || ""}
          margin="dense"
          id="key"
          name="key"
          label="Phrase"
          fullWidth
          variant="standard"
        />
        <TextField
          required
          defaultValue={selectedItem?.value || ""}
          margin="dense"
          id="value"
          name="value"
          label="Pronunciation"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        {selectedItem && <Button color={"error"} onClick={handleDelete} sx={{mr: "auto"}}>Delete</Button>}
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">{selectedItem ? "Edit" : "Create"}</Button>
      </DialogActions>
    </Dialog>
  )
}