import axios from "../config/axios-kb"


export const OrganizationRepository = {
  getAll: () => {
    return axios.get(`/organizations`);
  },
  getUserOrganizations: (username) => {
    return axios.get(`/organizations/member/${username}`);
  },
  delete: (id) => {
    return axios.delete(`/organizations/${id}`)
  },
  create: (organization) => {
    return axios.post('/organizations', organization)
  },
  edit: (id, organization) => {
    return axios.put(`/organizations/${id}`, organization)
  },
  publish: (id) => {
    return axios.patch(`/organizations/${id}/publish`)
  }
}