import React, {useContext, useEffect, useState} from "react";
import {
  Button, Dialog,
  Chip, DialogActions, DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography, Autocomplete, Box, Menu, Fade
} from "@mui/material";
import umAxios from "../../../../config/axios";
import {toast} from "react-toastify";
import {IconPlus} from "@tabler/icons-react";
import {Context as AppContext} from "../../../../context/AppContext";
import {HexColorPicker} from "react-colorful";

export const GeneralData = ({newProject, setNewProject}) => {
  const {state: {projects}} = useContext(AppContext);
  const [languages, setLanguages] = useState([]);
  const [createLabel, setCreateLabel] = useState(false);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await umAxios.get('/api/language-support/all');
        setLanguages(response.data);
      } catch (err) {
        toast.error(err.message);
      }
    }
    fetchLanguages();
  }, []);

  function filterUniqueByProperty(array, propertyName) {
    const uniqueMap = new Map();
    array.forEach(item => {
      uniqueMap.set(item[propertyName], item);
    });
    return Array.from(uniqueMap.values());
  }

  const labels = filterUniqueByProperty(projects?.map(project => project?.settings?.labels || []).flat(), 'name');

  return (
    newProject?.settings &&
    <Stack direction={"column"} gap={2} useFlexGap flexWrap={"wrap"}>
      <LabelDialog
        open={createLabel}
        labels={labels}
        handleClose={() => setCreateLabel(false)}
        handleSuccess={(label) => {
          setNewProject(prev => {
            const state = {...prev};
            if (prev?.settings?.labels && prev?.settings?.labels?.length > 0) {
              state.settings.labels.push(label);
            } else {
              state.settings.labels = [label];
            }
            return state;
          })
        }}
      />
      <TextField
        size={"small"}
        label={"Name"}
        value={newProject?.name}
        onChange={(e) => {
          setNewProject(prev => {
            const state = {...prev};
            state.name = e.target.value;
            return state;
          })
        }}
      />
      <TextField
        size={"small"}
        label={"Description"}
        multiline
        rows={3}
        value={newProject?.description}
        onChange={(e) => {
          setNewProject(prev => {
            const state = {...prev};
            state.description = e.target.value;
            return state;
          })
        }}
      />
      <FormControl size={"small"}>
        <InputLabel id={`language-label`}
                    sx={{textTransform: "capitalize"}}>Language</InputLabel>
        <Select
          labelId={`language-label`}
          id={`language-select`}
          label={"Language"}
          value={newProject?.settings?.language || "none"}
          onChange={e => {
            const lang = languages.find(i => i.id === e.target.value);
            setNewProject(prev => {
              const state = {...prev};
              state.settings.language = lang?.id || null;
              return state;
            })
          }}
        >
          <MenuItem value={"none"}><em>None</em></MenuItem>
          {languages?.map(item => <MenuItem key={item?.id} value={item?.id}>{item?.language} ({item?.code})</MenuItem>)}
        </Select>
      </FormControl>
      <div>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Typography fontWeight={700} fontSize={13} sx={{px: 1}}>Labels</Typography>
          <IconButton size={"small"} onClick={() => setCreateLabel(true)}>
            <IconPlus size={12}/>
          </IconButton>
        </Stack>
        <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
          {newProject?.settings?.labels?.map((label, index) => {
            return <Chip
              key={`${label?.name}-${label?.color}`}
              size={"small"} label={label?.name}
              sx={{fontSize: 12, bgcolor: label?.color, "&:hover": {bgcolor: label?.name}}}
              onDelete={() => {
                setNewProject(prev => {
                  const state = {...prev};
                  state.settings.labels = state.settings.labels?.filter((i, ind) => ind !== index);
                  return state;
                })
              }}/>
          })}
        </Stack>
      </div>
    </Stack>
  )
}


export default function LabelDialog({open, labels, handleClose, handleSuccess}) {
  const [label, setLabel] = useState({name: "", color: "#000"});

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          if (label?.name && label?.color) {
            handleSuccess(label);
            handleClose();
            setLabel({name: "", color: "#000"})
          }
        },
      }}
    >
      <DialogTitle>Create Label</DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{mt: 1, minWidth: "260px"}}
          size={"small"}
          renderInput={(params) => <TextField {...params} size={"small"} variant={"standard"} label="Label Name"/>}
          options={labels}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setLabel(newValue);
          }}
          onBlur={() => {
            const findedLabel = labels?.find(lbl => lbl?.name === label?.name);
            if (findedLabel) {
              setLabel(prev => ({...prev, color: findedLabel?.color || ""}))
            }
          }}
          inputValue={label?.name}
          clearOnBlur={false}
          onInputChange={(event, newInputValue) => {
            setLabel(prev => ({...prev, name: newInputValue || ""}));
          }}
          id="controllable-states-demo"
        />

        <ColorPicker
          color={label?.color}
          setColor={newColor => {
            setLabel(prev => ({...prev, color: newColor}));
          }}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Add</Button>
      </DialogActions>
    </Dialog>
  );
}

export const ColorPicker = ({color, setColor}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Chip label={color} sx={{mt: 1, bgcolor: color, minWidth: 100, "&:hover": {bgcolor: color} }} onClick={handleClick}/>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
          sx: {p: 0, bgcolor: "transparent"},
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{m: 0, p: 0}}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        slotProps={{
          paper: {sx: {p: '0 !important', m: 0, bgcolor: "transparent"}}
        }}
      >
        <HexColorPicker color={color} onChange={(newColor) => {
          setColor(newColor);
        }}/>
      </Menu>

    </Box>
  )
}