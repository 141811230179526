import {NodeType} from "../../../../model/ModelData";

export const editMenuItems = {
  [NodeType.START]: ["greet", "settings", "static", "dynamic"],
  [NodeType.TEXT]: ["greet", "settings", "llm_boost", "static"],
  [NodeType.TEACHING]: ["greet", "settings", "output_parser", "static"],
  [NodeType.QUERY]: ["greet", "settings", "llm_boost", "static", "dynamic"],
  [NodeType.QA]: ["greet", "please_wait_messages", "settings", "llm_boost", "slots", "static"],
  [NodeType.RECOMMENDATION]: ["greet", "please_wait_messages", "settings", "llm_boost", "output_parser", "slots", "static"],
  [NodeType.HOOK]: ["greet", "settings", "output_parser", "static", "dynamic"],
  [NodeType.EVENT_LISTENER]: ["greet", "settings", "output_parser", "static", "dynamic"],
  [NodeType.INPUT]: ["greet", "settings", "llm_boost", "static", "dynamic"],
  [NodeType.SWITCH]: ["greet", "settings", "static", "dynamic"],
  [NodeType.START_FORM]: ["greet", "settings", "static"],
  [NodeType.END_FORM]: ["greet", "settings", "static"],
  [NodeType.START_FLOW]: ["greet", "settings", "static", "dynamic"],
  [NodeType.SET_VAR]: ["vars", "static"],
  [NodeType.END]: ["greet"],
  [NodeType.JAVASCRIPT]: ["output_parser", "settings", "static"],
}

export const menuItemsInfo = {
  greet: {primary: "Greet messages", secondary: "Enter messages agent can send to the user"},
  please_wait_messages: {primary: "Please wait messages", secondary: "Enter messages agent can send to the user"},
  slots: {primary: "Slots", secondary: "Add slots"},
  settings: {primary: "Settings", secondary: "Add additional data to your node"},
  static: {primary: "Static routes", secondary: "Route your agent on other node with: next, no-match or fail"},
  dynamic: {primary: "Dynamic routes", secondary: "Route your agent on other node by catching some intent"},
  knowledge_base: {primary: "Knowledge base", secondary: "Enter knowledge base parameters"},
  vars: {primary: "Set variables", secondary: "Set global, flow or form variables"},
  output_parser: {primary: "Output parser", hook: "API Output parser", secondary: "Create your parser with Javascript code"},
  llm_boost: {primary: "LLM Booster", secondary: "A powerful AI technology capable of understanding and generating human-like text."},
}