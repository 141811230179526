import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import LoadingScreen from "../components/loading-screen/LoadingScreen";

export const WebSettingsContext = React.createContext({
  settings: null,
  searchParams: {
    organization: null,
    term: "",
  },
  onUpdateParams: () => {},
  onUnderMaintenance: () => {},
})

const id = 'blockflow';

export const WebSettingsContextProvider = props => {
  const [settings, setSettings] = useState(null);
  const [searchParams, setSearchParams] = useState({
    organization: null, term: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_JSON_SERVER}/web_settings/${id}`);
        const json = await response.json()
        if (json) {
          setSettings(json);
        } else {
          const data = {
            underMaintenance: false,
            models: []
          }
          await fetch(`${process.env.REACT_APP_JSON_SERVER}/web_settings`, {
            method: "POST",
            body: JSON.stringify(data)
          })
          setSettings(data);
        }
      } catch (e) {
        console.log(e)
        setSettings({})
        toast.error("Failed to fetch settings")
      }
    }

    fetchData()
  }, [])

  const onUnderMaintenance = async () => {
    try {
      const data = {
        ...settings,
        underMaintenance: !settings.underMaintenance
      }
      await fetch(`${process.env.REACT_APP_JSON_SERVER}/web_settings/${id}`, {
        method: "PUT",
        body: JSON.stringify(data)
      })
      setSettings(prev => ({...prev, ...data}));
      toast.success("Successfully updated settings")
    } catch (e) {
      console.error(e);
      toast.error(e.errorMessage)
    }
  }

  const onUpdateParams = (data) => {
    setSearchParams(prev => ({...prev, ...data}))
  }

  return (
    <WebSettingsContext.Provider
      value={{
        settings: settings,
        searchParams: searchParams, onUpdateParams: onUpdateParams,
        onUnderMaintenance: onUnderMaintenance
      }}
    >
      {settings === null ? <LoadingScreen/> : props.children}
    </WebSettingsContext.Provider>
  )
}