import {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {Stack, Typography} from "@mui/material";
import useWebSocket from "../../../../hooks/useWebSocket";
import moment from "moment/moment";
import {DatePicker} from "@mui/x-date-pickers";
import {ASRIConversationsRepository} from "../../../../repositories/ASRIConversationsRepository";

const initialState = {
    series: [],
    labels: [],
    options: {
        chart: {
            width: "100%",
            type: 'donut',
        },
        stroke: {
            width: 0,
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            showAlways: true,
                            show: true
                        },
                    },
                }
            }
        },
        legend: {
            position: 'bottom',
        },
        labels: [],
        colors: [
            '#66DA26',
            '#FF9800',
            '#E91E63',
            '#2E93fA',
            '#546E7A'
        ],
        states: {
            hover: {
                filter: 'none'
            }
        },
    }
}

export const ConversationCountStatistic = ({title, sessionId}) => {
    const dateLastMonth = new Date();
    const dateNow = new Date()
    dateLastMonth.setTime(dateNow.setHours(0, 0, 0, 0))
    dateNow.setTime(dateNow.setHours(0, 0, 0, 0))

    const [chartState, setChartState] = useState({...initialState})
    const [startDate, setStartDate] = useState(moment(dateLastMonth.setMonth(dateNow.getMonth() - 1)));
    const [endDate, setEndDate] = useState(moment(dateNow));
    const [conversationCount, setConversationCount] = useState(null);

    useEffect(() => {
        if (sessionId) {
            fetchData()
        }
    }, [sessionId, startDate, endDate])


    const fetchData = () => {
        const start = startDate?.format('YYYY-MM-DDT00:00:00') || null;
        const end = endDate === null ? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') : endDate.format('YYYY-MM-DDT23:59:59')
        ASRIConversationsRepository.getTotalConversations({sessionId, startDate: start, endDate: end})
          .then(response => {
              setConversationCount(response.data)
          })
          .catch(error => console.log(error));
    }

    useEffect(() => {
        if (conversationCount) {
            setChartState(prev => ({
                ...prev,
                series: [conversationCount?.finished, conversationCount?.redirected, conversationCount?.failed],
                options: {
                    ...prev.options,
                    labels: ["Finished", "Redirected", "Failed"]
                }
            }))
        }
    }, [conversationCount])

    return (
        <Stack direction={"column"} gap={1} className={"border-shadow"}
               sx={{p: {xs: 2, md: 4}, borderRadius: 2, flex: 1, maxHeight:"400px"}}>
            <Stack direction={"row"} gap={2} useFlexGap flexWrap={"wrap"} alignItems={"center"}>
                <Typography variant={"h5"} fontWeight={600}>{title}</Typography>
                <Stack direction={"row"} gap={2} sx={{ml: "auto"}}>
                    <DatePicker
                      format={"DD MMM YYYY"} label={"From"}
                      value={startDate} onChange={value => setStartDate(value)}
                      slotProps={{
                          textField: {
                              variant: "standard", size: "small",
                              sx: {maxWidth: "120px"}
                          }
                      }}
                    />
                    <DatePicker
                      format={"DD MMM YYYY"} label={"To"}
                      value={endDate} onChange={value => setEndDate(value)}
                      slotProps={{
                          textField: {
                              variant: "standard", size: "small",
                              sx: {maxWidth: "120px"}
                          }
                      }}
                    />
                </Stack>
            </Stack>
            {chartState && chartState.options && chartState.options.chart && chartState.options.chart.type &&
            (<ReactApexChart type="donut" options={chartState.options} series={chartState.series || []} height={350} />)
            }

        </Stack>
    )
}