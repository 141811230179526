import {TOKEN} from "../../../context/SignInContext";
import {ProjectAtom} from "../ProjectHomePage";
import {useAtomValue} from "jotai"


export const AsriPage = () => {
  const project = useAtomValue(ProjectAtom);
  return (
    <iframe title={"ASRI"}
            allow=""
            src={`${process.env.REACT_APP_ASRI_GATEWAY}/cross-origin/project/${project?.speechToText || project?.settings?.speechToText}/documents?auth=${localStorage.getItem(TOKEN)}`}
            style={{width: "100%", height: "100%", overflowX: "hidden", border:  0}}
            // onLoad={handleLoad}
    >
    </iframe>
  )
}