import {Grid, Stack} from "@mui/material";
import {ConversationCountStatistic} from "./components/ConversationCountStatistic";
import {Last30DaysStatistic} from "./components/Last30DaysStatistic";
import {useEffect, useState} from "react";
import {ASRIConversationsRepository} from "../../../repositories/ASRIConversationsRepository";
import {ActiveCalls} from "./components/ActiveCalls";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers";
import {ChatBotResponseTime} from "./components/ChatBotResponseTime";
import {SynthesisResponseTime} from "./components/SynthesisResponseTime";
import {TranscribeResponseTime} from "./components/TranscribeResponseTime";
import useWebSocket from "../../../hooks/useWebSocket";
import {ViewActiveConversationsModal} from "./components/ViewActiveConversationsModal";
import {ActiveCallsView} from "./components/ActiveCallsView";
import {VDNRedirectionStatistics} from "./components/VDNRedirectionStatistics";

export const DashboardPage = ({project}) => {
  const [conversationCount, setConversationCount] = useState({
    active: 0,
    finished: 0,
    terminated: 0,
    redirected: 0,
  });
  const [activeConversations, setActiveConversations] = useState([]);

  const updatedValue = useWebSocket(`/topic/active_calls/${project?.settings?.speechToText}`);

  const fetchData = () => {
    const sessionId = project?.settings?.speechToText;
    ASRIConversationsRepository.getConversationStatusCounts({sessionId})
      .then(response => {
        setConversationCount(response.data)
      })
      .catch(error => console.log(error));
    ASRIConversationsRepository.getActiveConversations({sessionId})
      .then(response => {
        setActiveConversations(response.data);
        // setCountActiveConversations(response.data.length);
      })
      .catch(error => console.log(error));
  }


  useEffect(() => {
    fetchData()
  }, [updatedValue])


  useEffect(() => {
    fetchData()
  }, [updatedValue]);

  return (
    <Grid container gap={0} sx={{p: 1, overflowY: "auto"}}>
      {/*<Grid item xs={12} sm={6} md={3} lg={2}*/}
      {/*      sx={{p: {xs: 1, md: 2}, minWidth: "210px", display: "flex", flexDirection: "column"}}>*/}
      {/*    <ActiveCalls project={project}/>*/}
      {/*</Grid>*/}

      <Grid item xs={12} sm={6} md={4} lg={3}
            sx={{p: {xs: 1, md: 1}, display: "flex", flexDirection: "column"}}>
        <ChatBotResponseTime responseTime={conversationCount?.chatbotResponseTime}
                             conversations={conversationCount.responseTimeStats || []}/>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}
            sx={{p: {xs: 1, md: 1}, display: "flex", flexDirection: "column"}}>
        <SynthesisResponseTime responseTime={conversationCount?.synthesisResponseTime}
                               conversations={conversationCount.responseTimeSynthesisStats || []}/>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}
            sx={{p: {xs: 1, md: 1}, display: "flex", flexDirection: "column"}}>
        <TranscribeResponseTime responseTime={conversationCount?.transcribeResponseTime}
                                conversations={conversationCount.responseTimeTranscribeStats || []}/>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}
            sx={{p: {xs: 1, md: 1}, display: "flex", flexDirection: "column"}}>
        <ActiveCalls activeConversations={activeConversations} hourlyConversations={conversationCount.last24HoursConversations}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}
            sx={{p: {xs: 1, md: 1}, display: "flex", flexDirection: "column"}}>
        <Last30DaysStatistic title={"Calls per "} project={project}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
            sx={{p: {xs: 1, md: 1}, display: "flex", flexDirection: "column"}}>
        <ActiveCallsView sessionId={project?.settings?.speechToText} activeConversations={activeConversations} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}
            sx={{p: {xs: 1, md: 1}, display: "flex", direction: "column"}}>
        <ConversationCountStatistic title={"Total Conversations"} sessionId={project?.settings?.speechToText} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8}
            sx={{p: {xs: 1, md: 1}, display: "flex", flexDirection: "column"}}>
        <VDNRedirectionStatistics fullWidth title={"Transferred Calls per VDN"} project={project}/>
      </Grid>
    </Grid>
  )
}