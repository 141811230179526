import {IconChartArea} from "@tabler/icons-react";
import {lighten} from "@mui/system";
import {Avatar, Box, Stack, Typography} from "@mui/material";
import ReactApexChart from "react-apexcharts";

export const DashboardCard = ({
                                title,
                                count,
                                startFrom,
                                icon,
                                color = "#3498db",
                                percent,
                                description,
                                chartSeries = [],
                                sx = {},
                                ...props
                              }) => {

  const Icon = icon ? icon : IconChartArea;
  const lightenedColor = lighten(color, 0.9); // Adjust the value to lighten the color as needed

  return (
    <Stack direction={"row"} gap={1}
           alignItems={"center"}
           sx={{p: {xs: 2, md: 3}, py: '0 !important', borderRadius: 2, flex: 1, ...sx}}
           className={"border-shadow"} {...props}>
      <Stack direction={"column"} gap={0}>
        <Stack direction={"column"} gap={1} fullWidth>
          <Avatar sx={{background: lightenedColor, color: color, p: 1, width: '2rem', height: '2rem'}}>
            <Icon size={50} color={color}/>
          </Avatar>
          <Box>
            <Typography fontSize={12} fontWeight={300} sx={{textWrap: "nowrap"}}>{description}</Typography>
            <Typography variant={"h5"} fontWeight={600} sx={{textWrap: "nowrap"}}>{title}</Typography>

          </Box>
        </Stack>
        <Typography variant={"h2"} fontWeight={700}>
          {count}
        </Typography>
      </Stack>
      {chartSeries !== undefined ? <Box sx={{flex: 3, pt: 2}}>
        <MiniLineChart title={title} series={chartSeries} color={color}/>
      </Box> : null}
    </Stack>
  );
}

const MiniLineChart = ({series, title, color = "#66DA26"}) => {

  const options = {
    chart: {
      type: "area",
      height: 150,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: false
        },
      }
      // stacked: true,
      // stackType: "100%",
    },
    grid: {
      show: false,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
        }
      },
      yaxis: {
        lines: {
          show: false  //or just here to disable only y axis
        }
      },
    },
    colors: [
      color,
      '#66DA26',
      '#FF9800',
      '#E91E63',
      '#2E93fA',
      '#546E7A'
    ],
    xaxis: {
      type: 'datetime',
      categories: series?.map(item => item?.dateCreated),
      show: false,
      labels: {
        show: true,
        datetimeUTC: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        // formatter: (text) => {
        //   console.log("FORMATTER", text)
        //   return text && isFinite(text) ? +text?.toFixed(0) : text
        // }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    fill: {
      opacity: 1
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy HH:mm'
      },
    },
  };
  const seriesData = [{
    name: "Value",
    data: series?.map(item => item?.value)
  }]

  return (
    <ReactApexChart type="area" options={options} series={seriesData || []} height={180}/>
  )
}
