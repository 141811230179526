

export const messages = [
  {
    "id": 3496,
    "dateCreated": "1990-04-06T00:32:21",
    "dateUpdated": "2001-12-24T00:12:48",
    "text": "Ut provident illum nemo similique delectus aliquam totam eos deleniti quisquam nesciunt facere ut unde aperiam soluta.",
    "nodeId": "e12ebe7a-1086-48dd-9df8-74f37e593ea5",
  },
  {
    "id": 6054,
    "dateCreated": "2022-01-15T21:42:02",
    "dateUpdated": "2024-09-15T11:55:36",
    "text": "Quis rerum ratione nemo voluptas ratione nemo impedit repudiandae tenetur.",
    "nodeId": "e12ebe7a-1086-48dd-9df8-74f37e593ea5",
  },
  {
    "id": 1261,
    "dateCreated": "1993-07-10T15:38:55",
    "dateUpdated": "1990-05-07T20:27:16",
    "text": "Ab iure architecto sunt soluta est inventore repellat dolorum laudantium nihil quo perferendis unde vitae ut facere qui.",
    "nodeId": "e12ebe7a-1086-48dd-9df8-74f37e593ea5",
  },
  {
    "id": 6902,
    "dateCreated": "1980-04-14T01:13:32",
    "dateUpdated": "1981-07-30T16:01:54",
    "text": "Praesentium repellat tenetur quae repellat repellendus ut iste dolorem provident quidem doloremque itaque excepturi fuga tempore modi blanditiis adipisci error.",
    "nodeId": "e12ebe7a-1086-48dd-9df8-74f37e593ea5",
  },
  {
    "id": 6543,
    "dateCreated": "2015-03-14T13:11:38",
    "dateUpdated": "1988-02-05T02:12:17",
    "text": "Accusantium aliquid iusto quasi repudiandae doloremque suscipit amet eius sequi maxime voluptatum voluptatem libero non aperiam.",
    "nodeId": "2963db64-61db-4765-bc2e-30a8cd797425",
  },
  {
    "id": 7671,
    "dateCreated": "1983-03-09T22:50:11",
    "dateUpdated": "1983-09-30T13:32:52",
    "text": "Cumque ipsam incidunt sint doloremque vel eligendi voluptatum ad.",
    "nodeId": "3a07232f-e5ec-472a-84ee-51cd887e7e49",
  },
  {
    "id": 6961,
    "dateCreated": "2010-04-16T11:55:29",
    "dateUpdated": "2001-11-27T10:28:37",
    "text": "Qui animi dolores eum consequatur totam minima quia maxime architecto quis debitis nihil aliquid adipisci.",
    "nodeId": "bd04c6dc-f79c-4b19-8c9d-884ecc34ea57",
  },
  {
    "id": 4234,
    "dateCreated": "1993-07-10T15:38:55",
    "dateUpdated": "1990-05-07T20:27:16",
    "text": "Ab iure architecto sunt soluta est inventore repellat dolorum laudantium nihil quo perferendis unde vitae ut facere qui.",
    "nodeId": "e12ebe7a-4929-48dd-9df8-74f37e593ea5",
  },
  {
    "id": 6902,
    "dateCreated": "1980-04-14T01:13:32",
    "dateUpdated": "1981-07-30T16:01:54",
    "text": "Praesentium repellat tenetur quae repellat repellendus ut iste dolorem provident quidem doloremque itaque excepturi fuga tempore modi blanditiis adipisci error.",
    "nodeId": "e12ebe7a-2332-48dd-9df8-74f37e593ea5",
  },
  {
    "id": 6543,
    "dateCreated": "2015-03-14T13:11:38",
    "dateUpdated": "1988-02-05T02:12:17",
    "text": "Accusantium aliquid iusto quasi repudiandae doloremque suscipit amet eius sequi maxime voluptatum voluptatem libero non aperiam.",
    "nodeId": "2963db64-1414-4765-bc2e-30a8cd797425",
  },
  {
    "id": 7671,
    "dateCreated": "1983-03-09T22:50:11",
    "dateUpdated": "1983-09-30T13:32:52",
    "text": "Cumque ipsam incidunt sint doloremque vel eligendi voluptatum ad.",
    "nodeId": "3a07232f-5234-472a-84ee-51cd887e7e49",
  },
  {
    "id": 6961,
    "dateCreated": "2010-04-16T11:55:29",
    "dateUpdated": "2001-11-27T10:28:37",
    "text": "Qui animi dolores eum consequatur totam minima quia maxime architecto quis debitis nihil aliquid adipisci.",
    "nodeId": "bd04c6dc-8567-4b19-8c9d-884ecc34ea57",
  },
]
