import Modal from "@mui/material/Modal";
import {Avatar, Grid, Stack, Typography} from "@mui/material";
import {IconPhotoAi} from "@tabler/icons-react";
import React from "react";
import {grey} from "@mui/material/colors";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: {xs: "90vw", sm: "80vw", md: "60vw", lg: "40vw"},
  maxHeight: {xs: "80vh", md: "80vh"},
  width: "100%",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};

export const SelectOrganizationModal = ({open, onSelect, handleClose, organizations}) => {

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Grid container sx={style} alignItems={"stretch"}>
        <Grid item xs={12} sx={{borderBottom: 1, borderColor: "divider", pb: 2}}>
          <Typography variant={"h3"}>Select Organizations</Typography>
        </Grid>
        {organizations?.map(organization => {
          return <Grid item key={organization?.id} xs={6} sm={4}
                       sx={{p: 2, display: "flex", flexDirection: "column", alignItems: "stretch"}}
          >
            <Stack direction={"column"} gap={2} onClick={() => onSelect(organization)}
                   sx={{mt: "auto", scale: "0.9", cursor: "pointer", "&:hover": {scale: "1"}}}>
              <Avatar
                sx={{
                  border: 1,
                  borderColor: "#153B50",
                  bgcolor: "transparent",
                  height: 120,
                  width: 120,
                  fontSize: 50,
                  borderRadius: 3,
                  mb: "auto", color: "#153B50"
                }}
              >{organization?.name[0] || <IconPhotoAi/>}
              </Avatar>
              <Typography fontWeight={700} color={grey[500]} sx={{height: "100%"}}
                          align={"center"}>{organization?.name}</Typography>
            </Stack>
          </Grid>
        })}
      </Grid>
    </Modal>
  )
}