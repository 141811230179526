import createContext from "./createContext";
import {toast} from "react-toastify";
import {v4 as uuidv4} from 'uuid';
import {FlowEntity, NodeEntity, NodeType, UserDetailsEntity} from "../model/ModelData";
import axios from "axios";

const appInitialState = {
  userDetails: {...UserDetailsEntity},
  projects: [],
}

const appReducer = (state, action) => {
  switch (action.type) {
    case 'update_flow':
      return {...state, flow: action.payload}
    case 'update_flows':
      return {...state, flows: action.payload}
    case 'update_projects':
      return {...state, projects: action.payload}
    default:
      return state;
  }
}

// these functions will act as repositories, here we will make calls to the database and be able to reuse them in different components
const createProject = (dispatch, authContext) => {
  return async (data) => {
    try {
      const uuid = uuidv4();
      if (!data?.id) {
        data.id = uuid;
      }
      data.userUid = authContext.currentUser.username;
      data.team = [authContext.currentUser.username];
      //
      const flow = {...FlowEntity, id: uuidv4()};

      const receiveMessageId = uuidv4();
      const receiveMessageNode = {
        id: receiveMessageId,
        name: "Receive Message",
        type: NodeType.EVENT_LISTENER,
        coords: {
          x: 150,
          y: 150
        },
        parameters: [],
        data: {
          greet: [],
          max_ttl: 4,
          api_url: "https://chatui.dev.openbrain.io/d06b2a95-f87e-4306-9bc5-1e54567b5c5b",
          api_method: "POST",
          target: "flow",
          params: [
            {
              id: uuidv4(),
              source: null,
              scope: "flow"
            }
          ],
          token: uuidv4()
        },
        routes: {
          static: {
            next: {
              id: uuidv4(),
              target: " ",
              name: null,
              type: "next",
              backstack: false,
              data: {
                greet: "",
                params: []
              }
            },
            no_match: null,
            fail: null
          },
          dynamic: []
        }
      }


      const sendMessageId = uuidv4();
      const sendMessageNode = {
        ...NodeEntity,
        id: sendMessageId,
        type: NodeType.HOOK,
        coords: {x: 150, y: 0},
        data: {
          greet: [],
          max_ttl: 4,
          api_url: `${process.env.REACT_APP_CHATBOT}/${sendMessageId}`,
          api_method: "POST",
          target: "flow",
          params: [
            {
              id: uuidv4(),
              source: null,
              scope: "flow"
            }
          ]
        },
        name: "Send Message",
        parameters: [],
        routes: {
          static: {
            next: {
              id: receiveMessageId,
              target: " ",
              name: null,
              type: "next",
              backstack: false,
              data: {
                greet: "",
                params: []
              }
            },
            no_match: null,
            fail: null
          },
          dynamic: []
        }
      }
      const startNode = {
        ...NodeEntity,
        id: uuidv4(),
        name: "Start",
        type: NodeType.START,
        data: {
          greet: []
        },
        routes: {
          static: {
            next: {
              id: uuidv4(),
              type: "next",
              target: sendMessageNode.id,
              data: {}
            },
          }
        }
      }
      flow.nodes = [startNode, sendMessageNode, receiveMessageNode];
      data.flows = [flow];
      data.entryNode = startNode?.id;

      console.log(data);
      if (data.id === uuid) {
        await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects`, {
          method: "POST",
          body: JSON.stringify(data)
        })
      } else {
        await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects/${data.id}`, {
          method: "PUT",
          body: JSON.stringify(data)
        })
      }
      await getUserProjects(dispatch, authContext)();
      toast.success("Successfully created project.")
    } catch (err) {
      toast.error(err);
    }
  }
}

const publishVersion = (dispatch, authContext) => {
  return async (project) => {
    try {
      if (!project) {
        throw Error("Project not found");
      }
      const newProject = {...project};
      const newVersion = {...newProject};
      newVersion.id = uuidv4();
      newVersion.projectId = newProject.id;
      newVersion.createdAt = new Date().getTime();
      newVersion.createdBy = authContext.currentUser.username;
      if (newVersion.versions) {
        delete newVersion.versions;
      }
      await fetch(`${process.env.REACT_APP_JSON_SERVER}/project_versions`, {
        method: "POST",
        body: JSON.stringify(newVersion)
      })

      if (!newProject.versions) {
        newProject.versions = [];
      }
      newProject.versions.push(
        {
          id: newVersion.id,
          createdAt: newVersion.createdAt,
          version: `V${(newProject.versions.length + 1).toString().padStart(2, '0')}`
        }
      );
      await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects/${newProject.id}`, {
        method: "PUT",
        body: JSON.stringify(newProject)
      })

      const response = await axios.get(`https://intents.vb.openbrain.io/publish?project_id=${newProject.id}&export_url=${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/apiProject/${newProject.id}`)
      if (response.status !== 200) {
        toast.error("Error! Please try again.");
        throw Error("Publish version failed!");
      }

      await getUserProjects(dispatch, authContext)();
      toast.success("Successfully published new version.")
    } catch (err) {
      toast.error(err);
    }
  }
}

const updateProject = (dispatch, authContext) => {
  return async (data) => {
    try {
      const uuid = uuidv4();
      if (!data?.id) {
        data.id = uuid;
      }
      if (!data.userUid) {
        data.userUid = authContext.currentUser.username;
      }
      if (data.id !== uuid) {
        const response = await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects/${data.id}`);
        const newProject = {...response.data, ...data};
        await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects/${data.id}`, {
          method: "PUT",
          body: JSON.stringify(newProject)
        })
      } else {
        await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects`, {
          method: "POST",
          body: JSON.stringify(data)
        })
      }
      await getUserProjects(dispatch, authContext)();
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  }
}

const getUserProjects = (dispatch, authContext) => {
  return async () => {
    try {
      if (authContext?.currentUser && authContext?.isAuth) {
        const response = await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects/`);
        const data = await response.json()
        dispatch({type: "update_projects", payload: data || []});
        console.log(data)
      }
    } catch
      (err) {
      toast.error(err.message);
    }
  }
}

export const {Provider, Context} = createContext(
  appReducer,
  {
    getUserProjects,
    updateProject,
    createProject,
    publishVersion
  },
  {...appInitialState}
)