import {Box, List, ListItem, ListItemText, Stack, Tooltip, Typography} from "@mui/material";
import {Handle, Position} from "reactflow";
import {green, grey} from "@mui/material/colors";
import {IconAd, IconCircleFilled, IconPlus, IconSquare} from "@tabler/icons-react";
import React, {memo} from "react";
import {nodeWidth, nodeHeight} from "../react-flow-sort";

function TextNode({id, data}) {
  const {messages, activeCalls} = data;

  return (
    <Box sx={{position: "relative", maxWidth: nodeWidth, width: "100%",  maxHeight: nodeHeight, bgcolor: "#f4f4f4", borderRadius: 2, border: 1, borderColor: "white"}}  className={"border-shadow"}>
      <Handle type="target" position={Position.Left} id={id} />
      <Stack direction={"row"} gap={1} sx={{position: "absolute", top: 0, left: 10, transform: "translateY(-125%)"}}>
        {activeCalls?.slice(0, 3)?.map(call => {
          return <Stack key={call} direction={"row"} alignItems={"center"} gap={0.5} sx={{color: green[300]}}>
              <IconCircleFilled size={9} />
            <Typography fontSize={9} color={"#010101"}>{call}</Typography>
          </Stack>
        })}
        <Tooltip title={activeCalls?.slice(3)?.map(call => <span key={call} style={{display: "block", padding: "5px 10px"}}>{call}</span>)}>
          <Typography fontSize={9} color={grey[600]}>+ {activeCalls?.length - 3} more</Typography>
        </Tooltip>
      </Stack>

      <div className="custom-node__header">
        <Stack direction={"row"} gap={1}>
          <IconSquare size={13} />
          <Typography sx={{fontSize: 9, fontWeight: "bold"}}>Text</Typography>
        </Stack>
      </div>
      <Box sx={{p: 0.5}}>
        <List sx={{p: 0, m: 0, bgcolor: "#fefefe", borderRadius: 1, maxHeight: `calc(${nodeHeight} - 100px)`}}>
          <ListItem sx={{py: 0.5, px: 1}}>
            <ListItemText primary={"Messages"} primaryTypographyProps={{fontWeight: 600, fontSize: 10}} />
          </ListItem>
          {messages?.map(message => {
            return <ListItem key={message} sx={{borderTop: 1, borderColor: "divider", py: 0.5, px: 1}}>
              <ListItemText primary={message} primaryTypographyProps={{fontSize: 10}} />
            </ListItem>
          })}

        </List>
        <Handle type="source" position={Position.Right} id={id} />

      </Box>
    </Box>
  )
}

export default memo(TextNode);
