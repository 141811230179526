import React, {useContext, useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import {Button, Fade, IconButton, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {IconSettings, IconTrash, IconX} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {GeneralData} from "../../pages/project/settings/components/GeneralData";
import {Members} from "../../pages/project/settings/components/Members";
import {SignInContext} from "../../context/SignInContext";
import useConfirm from "../../hooks/useConfirm";
import { writeBatch, doc } from "firebase/firestore";
import {PROJECT_VERSION_COLLECTION, PROJECTS_COLLECTION} from "../../db/collections";
import {useNavigate} from "react-router-dom";
import {db} from "../../config/firebase";
import {Context as AppContext} from "../../context/AppContext";
import {toast} from "react-toastify";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {xs: "80vw", sm: "70vw", md: "50vw", lg: "30vw"},
  maxWidth: "80vw",
  minHeight: "60vh",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const SettingsModal = ({open, handleClose, project, onUpdate}) => {
  const authContext = useContext(SignInContext);
  const {getUserProjects} = useContext(AppContext);
  const [newProject, setNewProject] = useState(null);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this project?',
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (open && project) {
      console.log(project?.settings);
      setNewProject({...project});
    }
  }, [open, project])

  const onCreate = () => {
    onUpdate(newProject);
    onClose();
  }

  const onClose = () => {
    setNewProject(null);
    handleClose();
  }

  const onDelete = async () => {
    const response = await confirmDelete();
    if (response) {
      try {
        const batch = writeBatch(db);
        // Delete versions
        if (newProject?.versions?.length){
          for (const version of newProject?.versions) {
            // Delete version
            const versionRef = doc(db, PROJECT_VERSION_COLLECTION, version?.id);
            batch.delete(versionRef);
          }
        }

        // Delete project
        const projectRef = doc(db, PROJECTS_COLLECTION, newProject?.id);
        batch.delete(projectRef);

        await batch.commit();
        await getUserProjects();

        navigate("/")
        onClose();
      } catch (err) {
        toast.error(err);
      }

      return;
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={onClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>
          <Dialog />

          <Stack direction={"column"} sx={style}>
            <Stack direction={"row"} gap={1}>
              <IconSettings/>
              <Typography variant={"h4"} fontWeight={"bold"} sx={{mb: 1}}>Project Settings</Typography>
            </Stack>

            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="General Data" {...a11yProps(0)} />
                <Tab label="Members" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <GeneralData newProject={newProject} setNewProject={setNewProject} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Members newProject={newProject} setNewProject={setNewProject} />
            </CustomTabPanel>
            <Stack direction={'row'} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{mt: "auto", pt: 1.5}}>
              <Stack direction={'row'} gap={1} >
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  sx={{borderRadius: 0, textTransform: "capitalize"}}
                  onClick={onCreate}
                >
                  Save
                </Button>
                <Button
                  variant={"outlined"}
                  color={"secondary"}
                  sx={{borderRadius: 0, textTransform: "capitalize"}}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>

              <Button
                variant={"outlined"}
                color={"error"}
                onClick={onDelete}
                disabled={authContext.currentUser?.username !== newProject?.userUid}
                sx={{borderRadius: 0, textTransform: "capitalize"}}
              >
                <IconTrash size={15} style={{marginRight: 5}} />
                Remove Project
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}