import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React from "react";

export const SIPData = ({project, onChange}) => {

  return (
    <Grid container sx={{px: 2, py: 0, pb: 2}}>
      <Grid item xs={12} md={6} sx={{p: 1}}>
        <TextField
          label={"Host"}
          value={project?.host || ""}
          name={"host"}
          fullWidth
          placeholder={"ex: myhost.com"}
          InputLabelProps={{shrink: true}}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} sx={{p: 1}}>
        <TextField
          label={"Port"}
          value={project?.port || ""}
          name={"port"}
          fullWidth
          placeholder={"ex: 5050"}
          InputLabelProps={{shrink: true}}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} sx={{p: 1}}>

        <FormControl fullWidth>
          <InputLabel id={`sip-label`}
                      shrink={true}
                      sx={{textTransform: "capitalize"}}>Protocol</InputLabel>
          <Select
            labelId={`sip-label`}
            id={`sip-select`}
            label={"Protocol"}
            name={"protocol"}
            value={project?.protocol || ""}
            onChange={onChange}
          >
            <MenuItem value={""}><em>None</em></MenuItem>
            <MenuItem value={"TCP"}>TCP</MenuItem>
            <MenuItem value={"UDP"}>UDP</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{p: 1}}>

        <FormControl fullWidth>
          <InputLabel id={`gender-label`}
                      shrink={true}
                      sx={{textTransform: "capitalize"}}>Gender</InputLabel>
          <Select
            labelId={`gender-label`}
            id={`gender-select`}
            label={"Gender"}
            name={"gender"} disabled
            value={project?.gender || "male"}
            onChange={onChange}
          >
            <MenuItem value={""}><em>None</em></MenuItem>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={4} sx={{p: 1}}>
        <TextField
          label={"Talk duration"}
          value={project?.talkDuration || ""}
          name={"talkDuration"}
          inputProps={{min: 4, max: 100}}
          fullWidth type={"number"}
          placeholder={"(Min: 4 - Max: 100)"}
          error={project?.talkDuration && (project?.talkDuration < 4 || project?.talkDuration > 100)}
          helperText={"Time (in seconds) a customer speaks."}
          InputLabelProps={{shrink: true}}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}