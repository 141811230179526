import {IconPhoneCall} from "@tabler/icons-react";
import {DashboardCard} from "./DashboardCard";
import {useEffect, useState} from "react";
import moment from "moment/moment";

export const ActiveCalls = ({activeConversations, hourlyConversations}) => {

  const [hourlyStats, setHourlyStats] = useState([]);

  useEffect(() => {
    if (hourlyConversations && hourlyConversations?.length) {
      const groupedByHour = hourlyConversations.reduce((acc, conversation) => {
        //"2024-09-13T00:00:48.725266"
        const hour = moment(conversation.dateCreated).format("YYYY-MM-DDTHH:00:00");

        if (!acc[hour]) {
          acc[hour] = 0;
        }
        acc[hour] += 1;

        return acc;
      }, {});
      // Step 3: Map to desired format and sort by hour
      const formattedStats = Object.entries(groupedByHour)
        .map(([hour, value]) => ({
          hour,
          value,
        }))
        // Sorting by hour in ascending order
        .sort((a, b) => a.hour.localeCompare(b.hour));
      console.log(groupedByHour)
      console.log(formattedStats);
      setHourlyStats(formattedStats);
    }
  }, [hourlyConversations]);

  return (
    <>
      <DashboardCard
        title={`${activeConversations.length} Calls`}
        icon={IconPhoneCall}
        color={"#66DA26"}
        description={"ACTIVE CALLS"}
        chartSeries={hourlyStats?.map(item => ({
          id: item?.hour,
          dateCreated: item?.hour,
          value: item.value,
        })) || []}
      />
    </>
  );
};
