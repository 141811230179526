import React from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  IconLogout,
  IconMenu,
} from "@tabler/icons-react";
import {deepPurple} from "@mui/material/colors";
import {Link} from "react-router-dom";
import {signOut} from "firebase/auth";
import {auth} from "../../../config/firebase";

export const HeaderDrawer = ({open, handleClose, openCart}) => {

  const logoutHandle = async () => {
    try {
      await signOut(auth);
      handleClose();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      sx={{
        height: "100vh",
        width: '100%',
        flex: 1,
        display: {xs: "inherit", md: "none"},

      }}
      PaperProps={{
        sx: {
          maxWidth: "360px",
          width: "100% !important",
        },
      }}
    >
      <Stack direction={"column"} gap={1} sx={{
        height: "100vh",
        maxWidth: "360px",
        width: "100% !important",
        bgcolor: "#FFF"
      }}>
        <Box sx={{p: 2, bgcolor: "#FFF"}}>
          <IconButton onClick={handleClose}>
            <IconMenu/>
          </IconButton>
        </Box>
        <Box sx={{flex: 1, maxHeight: "100%", overflowY: 'auto'}}>
          <List>

            <ListItemButton onClick={handleClose} component={Link} to={"/"}>
              <ListItemText>
                <Typography variant={"subtitle2"} fontWeight={"bold"}>Projects</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton onClick={logoutHandle}>
              <ListItemIcon>
                <IconLogout/>
              </ListItemIcon>
              <ListItemText primary={"Logout"}/>
            </ListItemButton>

          </List>
        </Box>
      </Stack>
    </Drawer>
  )
}
