import {v4 as uuidv4} from 'uuid';
import {
    IconKeyboard,
    IconPlayerPlay, IconTerminal, IconTopologyStar,
    IconVariable, IconChalkboard, IconBrandJavascript
} from "@tabler/icons-react";
import {IconHook} from "../assets/icons/IconHook";
import {IconQuery} from "../assets/icons/IconQuery";
import {IconSwitch} from "../assets/icons/IconSwitch";
import {IconExit} from "../assets/icons/IconExit";
import {IconText} from "../assets/icons/IconText";
import {IconQA} from "../assets/icons/IconQA";

export const UserDetailsEntity = {
    id: uuidv4(),
    displayName: "",
    email: "",
    authUid: "",
    photoURL: "",
}

export const IntentType = {
    GLOBAL: "global",
    LOCAL: "local",
}

export const KB_TYPE = {
    URL: "URL",
    API: "API"
}

export const IntentEntity = {
    id: uuidv4(),
    name: "intent-name",
    type: "GLOBAL", // Global/Local
    flow: "", //flow id
    entries: [
        "Здраво како си",
        "Zdravo kako si",
        "Zdravo jas sum super a ti?"
    ]
}

export const ProjectEntity = {
    name: "My first project",
    description: "This project is for A1 MK",
    entryNode: "", // Start node
    createdAt: new Date().getTime(),
    intents: [],
    flows: [],
    entities: [],
    settings: {
        kb_api: "https://api.kb.openbrain.io",
        kb_method: "GET",
        qa_api: "https://qa-mk.openbrain.io",
        qa_method: "POST"
    }
}

export const FlowEntity = {
    id: uuidv4(),
    name: "Home",
    nodes: []
}

export const InitialNodes = [
    {
        "id": "a6641229-ca26-4877-b966-17e548d319af",
        "name": "Hello",
        "entryNode": "a62ae518-165d-4357-9916-fe57bb07bc6e",
        "createdAt": 1718266634860,
        "intents": [],
        "flows": [
            {
                "id": "57755a41-a5f8-478f-a6cc-1b5785eeb369",
                "name": "Home",
                "nodes": [
                    {
                        "id": "a62ae518-165d-4357-9916-fe57bb07bc6e",
                        "name": "Start",
                        "type": "start",
                        "coords": {
                            "x": 0,
                            "y": 0
                        },
                        "parameters": [],
                        "data": {
                            "greet": [
                                "Добредојде во контакт центарот на А1",
                                "Добредојде"
                            ]
                        },
                        "routes": {
                            "static": {
                                "next": {
                                    "id": "80affce7-11a9-475a-b04a-0ff45834743e",
                                    "type": "next",
                                    "target": "0dc813e6-73be-4207-bed0-479b91cd81e3",
                                    "data": {},
                                    "name": "Send Message"
                                }
                            }
                        }
                    },
                    {
                        "id": "0dc813e6-73be-4207-bed0-479b91cd81e3",
                        "name": "Send Message",
                        "type": "hook",
                        "coords": {
                            "x": 162.22222222222217,
                            "y": -9.999999999999986
                        },
                        "parameters": [],
                        "data": {
                            "greet": [],
                            "max_ttl": 4,
                            "api_url": "",
                            "api_method": "GET",
                            "target": "flow",
                            "params": [
                                {
                                    "id": "e108dd7a-9766-4cf4-9848-d4b432f85f87",
                                    "source": null,
                                    "scope": "flow"
                                }
                            ]
                        },
                        "routes": {
                            "static": {
                                "next": {
                                    "id": "50d5a190-682b-407e-a357-ce2350ab0473",
                                    "target": " ",
                                    "name": null,
                                    "type": "next",
                                    "backstack": false,
                                    "data": {
                                        "greet": "",
                                        "params": []
                                    }
                                },
                                "no_match": null,
                                "fail": null
                            },
                            "dynamic": []
                        }
                    },
                    {
                        "id": "d06b2a95-f87e-4306-9bc5-1e54567b5c5b",
                        "name": "event listener [2]",
                        "type": "event_listener",
                        "coords": {
                            "x": 150,
                            "y": 150
                        },
                        "parameters": [],
                        "data": {
                            "greet": [],
                            "max_ttl": 4,
                            "api_url": "https://chatui.dev.openbrain.io//d06b2a95-f87e-4306-9bc5-1e54567b5c5b",
                            "api_method": "POST",
                            "target": "flow",
                            "params": [
                                {
                                    "id": "1cd5c33c-ee6b-47f1-b720-9b5ae598124a",
                                    "source": null,
                                    "scope": "flow"
                                }
                            ],
                            "token": "c407e270-e7c1-4961-b13d-f5578e6423db"
                        },
                        "routes": {
                            "static": {
                                "next": {
                                    "id": "3919394d-8555-4919-99c0-03b9677dd8e4",
                                    "target": " ",
                                    "name": null,
                                    "type": "next",
                                    "backstack": false,
                                    "data": {
                                        "greet": "",
                                        "params": []
                                    }
                                },
                                "no_match": null,
                                "fail": null
                            },
                            "dynamic": []
                        }
                    }
                ]
            }
        ],
        "entities": [],
        "settings": {
            "kb_api": "https://api.kb.openbrain.io",
            "kb_method": "GET",
            "qa_api": "https://qa-mk.openbrain.io",
            "qa_method": "POST",
            "organization": 6,
            "language": 1,
            "organization_type": "IT",
            "speechToText": 10,
            "category": "4"
        },
        "userUid": "admin",
        "team": [
            "admin"
        ]
    }
]

export const NodeType = {
    START: "start",
    QA: "qa",
    RECOMMENDATION: "recommendation",
    TEXT: "text",
    QUERY: "query",
    END: "end",
    START_FORM: "start_form",
    END_FORM: "end_form",
    INPUT: "input",
    START_FLOW: "start_flow",
    END_FLOW: "end_flow",
    SET_VAR: "set_var",
    JAVASCRIPT: "javascript",
    HOOK: "hook",
    EVENT_LISTENER: "event_listener",
    SWITCH: "switch",
    TEACHING: "teach",
}

export const NodeTypeIcon = {
    start: IconPlayerPlay,
    qa: IconQA,
    text: IconText,
    query: IconQuery,
    end: IconExit,
    start_form: IconPlayerPlay,
    recommendation: IconTerminal,
    end_form: IconExit,
    input: IconKeyboard,
    start_flow: IconTopologyStar,
    end_flow: IconExit,
    set_var: IconVariable,
    hook: IconHook,
    switch: IconSwitch,
    teach: IconChalkboard,
    javascript: IconBrandJavascript,
}

export const ScopeType = {
    FLOW: "flow",
    GLOBAL: "global",
    FORM: "form",
}

export const TargetType = {
    FLOW: "flow",
    GLOBAL: "global",
    FORM: "form",
}

export const NodeEntity = {
    id: "",
    name: "",
    type: "", // NodeType
    coords: { x: 0, y: 0 },
    parameters: [],
    data: {
        greet: []
    },
    routes: {
        static: {
            next: {
                id: uuidv4(),
                target: null,
                name: "",
                type: "next",
                backstack: true,
                data: {
                    greet: ""
                }
            },
            no_match: {
                id: uuidv4(),
                target: null,
                name: "",
                type: "no_match",
                backstack: true,
                data: {
                    greet: ""
                }
            },
            fail: {
                id: uuidv4(),
                target: null,
                name: "",
                type: "fail",
                backstack: true,
                data: {
                    greet: ""
                }
            },
        },
        dynamic: []
    }
}


export const HookNodeEntity = {
    id: "init-node",
    name: "init-node-1",
    type: "hook",
    data: {
        api_url: "https://api.kb.openbrain.io/api/user/active",
        api_method: "GET",
        target: "global",
        params: [
            {
                source: "organization_id",
                scope: "global"
            }
        ]
    },
    routes: {
        static:  {
            next: {
                route_id: "route-1",
                type: "next",
                target: "choice-page-1",
                data: {
                    greet: ""
                }
            }
        }
    }
}