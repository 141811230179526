import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ASRIVoicebotPropertiesRepository} from "../../../../repositories/ASRIVoicebotProperties";
import {IconDeviceFloppy} from "@tabler/icons-react";
import {toast} from "react-toastify";


export const VoicebotConfiguration = ({newProject}) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ASRIVoicebotPropertiesRepository.getAllProperties()
      .then(response => setProperties(response.data?.sort((a, b) => b.propertyName.localeCompare(a.propertyName))))
      .catch(error => console.log(error))
  }, [])

  const onChange = (e) => {
    const key = e?.target?.name;
    const value = e?.target?.value;

    if (key) {
      setProperties(prev => {
        return prev?.map(item => {
          if (item?.propertyName === key) {
            return {...item, value: value};
          }
          return {...item}
        })
      })
    }
  }

  const onSubmit = (e) => {
    e?.preventDefault();

    ASRIVoicebotPropertiesRepository.updateProperties(properties)
      .then(response => {
        toast.success("Voice bot properties saved");
      })
      .catch(error => {
        toast.error(error?.message || "Failed to save properties");
      })
  }

  const mapPropertyField = ({propertyName, value}) => {
    if (["model_tts", "model_stt"].includes(propertyName)) {
      return <FormControl fullWidth>
        <InputLabel id={`${propertyName}-label`}
                    sx={{textTransform: "capitalize"}}>{propertyName?.replaceAll("_", " ")}</InputLabel>
        <Select
          labelId={`${propertyName}-label`}
          id={`${propertyName}-select`}
          value={value} name={propertyName}
          label={propertyName?.replaceAll("_", " ")}
          onChange={onChange} disabled
        >
          <MenuItem value={"1"}>Default Model</MenuItem>
        </Select>
      </FormControl>
    }

    return (
      <TextField
        value={value} fullWidth
        name={propertyName}
        onChange={onChange}
        label={propertyName?.replaceAll("_", " ")}
        InputLabelProps={{sx: {textTransform: "capitalize"} }}
      />
    )
  }

  return (
    <Grid container spacing={2} sx={{p: 3}}>
      {
        properties?.map(property => {
          return (
            <Grid item key={property.propertyName} xs={12} md={4} lg={3}>
              {mapPropertyField(property)}
            </Grid>
          )
        })
      }
      <Grid item xs={12}>
        <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
          <div/>
          <Button type={"submit"} variant={"contained"} disabled={loading}
                  onClick={onSubmit}
                  color={"success"} sx={{borderRadius: "8px !important", border: 1}}
                  startIcon={<IconDeviceFloppy/>}>
            Save
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}