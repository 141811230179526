import axios from "../config/axios-asri"


export const ASRIConversationsRepository = {
  getConversationStatusCounts: ({sessionId}) => {
    return axios.get(`/api/conversation/${sessionId}/statistics`)
  },
  getConversationsByDate: ({sessionId, startDate, endDate}) => {
    return axios.get(`/api/conversation/filter/${sessionId}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      }
    })
  },
  getConversationsPaged: (page = 0, size = 10) => {
    return axios.get(`/api/conversation/voice`, {
      params: {
        page, size
      }
    })
  },
  getActiveConversations: ({sessionId}) => {
    return axios.get(`/api/conversation/${sessionId}/active`)
  },
  getTotalConversations: ({sessionId, startDate, endDate}) => {
    return axios.get(`/api/conversation/total/${sessionId}`,{
      params: {
        startDate, endDate
      }
    })
  },
  getVDNRedirectsBetweenDates: ({sessionId, startDate, endDate}) => {
    return axios.get(`/api/conversation/vdn_redirects/${sessionId}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      }
    })
  },
  getFinishedConversationTop5:  (sessionId) => {
    return axios.get(`/api/conversation/${sessionId}/finished`)
  },
}