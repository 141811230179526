import React, {useContext, useEffect, useState} from "react";
import {OrganizationRepository} from "../../repositories/OrganizationRepository";
import {toast} from "react-toastify";
import {
  Avatar,
  Box, Button, Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, Grid,
  IconButton, InputLabel, MenuItem, Select,
  Stack, Switch,
  TextField, Typography
} from "@mui/material";
import {IconCamera, IconPencil, IconPlus, IconTrash} from "@tabler/icons-react";
import axios from "../../config/axios";
import {SignInContext, TOKEN} from "../../context/SignInContext";
import useConfirm from "../../hooks/useConfirm";
import {v4 as uuid} from "uuid";
import {uploadImage} from "../../utils/uploadImage";
import {grey} from "@mui/material/colors";
import moment from "moment";


export const Organizations = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const {organizations, updateOrganizations} = useContext(SignInContext);

  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this organization?',
  );

  useEffect(() => {
    if (!organizations?.length) {
      updateOrganizations();
    }
  }, [])


  useEffect(() => {
    console.log(organizations)
  }, [organizations])
  const onPublish = (id) => {
    OrganizationRepository.publish(id)
      .then(() => {
        // toast.success("Successfully published organization");
        updateOrganizations();
      })
      .catch(error => {
        toast.error("Failed to publish organization");
      })
  }

  const handleDelete = async (id) => {
    const response = await confirmDelete();
    if (response) {
      OrganizationRepository.delete(id)
        .then(() => {
          toast.success("Successfully deleted organization");
          updateOrganizations();
        })
        .catch(error => {
          toast.error("Failed to delete organization");
        })
    }
  }

  return (
    <>
      <Dialog/>
      <CreateOrganization
        handleClose={() => {
          setOpenModal(false);
          setSelectedOrganization(null);
        }}
        open={openModal}
        selectedOrganization={selectedOrganization}
        onSuccess={updateOrganizations}
      />
      <Grid sx={{mx: "auto", pb: 3}} container>
        <Grid sm={12}>
          <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{mx: 2, my: 3}}>
            <Box maxWidth={"md"}>
              <Stack direction={"row"} gap={1} alignitems={"center"}>
                <Typography variant={"h3"}>Organization management</Typography>
              </Stack>
              {/*<Typography variant={"subtitle2"} fontWeight={400} sx={{pt: 1}}>*/}
              {/*  A knowledge base for AI is a centralized repository that stores and organizes information to train and*/}
              {/*  support AI systems.*/}
              {/*  /!*We use topics to categorize related content and slots to break down information into specific sections, ensuring efficient data management and retrieval.*!/*/}
              {/*</Typography>*/}
            </Box>
            <Button
              sx={{boxShadow: 0}} color={"secondary"}
              onClick={() => {
                setOpenModal(true);
                setSelectedOrganization(null);
              }}
              disabled
              startIcon={<IconPlus size={18}/>}
            >
              Create Organization
            </Button>
          </Stack>
        </Grid>
        {organizations?.map(organization => (
          <Grid key={organization?.id} item xs={12} sm={6} md={4} lg={3}
                sx={{p: 1}}>
            <Stack
              direction={"column"} gap={0.5}
              className={"border-shadow"}
              sx={{
                p: 2,
                flex: 1,
                bgcolor: "#fefeff",
                borderRadius: 4,
              }}
            >

              <Stack direction={"row"} gap={2} justifyContent={"space-between"} sx={{pb: 0.5}}
                     alignItems={"flex-start"}>
                <Typography fontSize={13} fontWeight={600} sx={{flex: 1}}
                            color={grey[700]}>{moment(organization?.createdAt).format("DD MMM YYYY")}</Typography>

                <IconButton
                  variant={"contained"} sx={{borderRadius: "50% !important"}}
                  onClick={() => {
                    setSelectedOrganization(organization);
                    setOpenModal(true);
                  }}>
                  <IconPencil size={18}/>
                </IconButton>
                <IconButton
                  variant={"contained"} color={"error"}
                  sx={{borderRadius: "50% !important"}}
                  onClick={() => handleDelete(organization?.id)}>
                  <IconTrash size={18}/>
                </IconButton>
              </Stack>

              <Stack direction={"row"} gap={1} justifyContent={"space-between"} sx={{pb: 0.5}}
                     alignItems={"flex-start"}>
                <img
                  src={"/a1-logo.png"}
                  alt={"Project"}
                  width={70} height={70}
                  style={{objectFit: 'scale-down'}}
                />
                <Stack direction={"column"} sx={{flex: 1}}>
                  <Typography fontSize={16} fontWeight={700} color={"#153B50"}>{organization?.name}</Typography>
                  <Typography fontSize={14} fontWeight={500} color={"#153B50"}>{organization?.organization_type ||
                    <em>Please add organization type</em>}</Typography>
                </Stack>

              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>

                <Box sx={{flex: 1}}>
                  <Typography fontSize={13} fontWeight={700} color={grey[900]} sx={{pb: 0.5}}>Members</Typography>
                  <Typography fontSize={13} fontWeight={500} color={grey[900]} sx={{pb: 0.5}}>
                    {organization?.members?.join(", ") || <em>There is no members in this organization</em>}
                  </Typography>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <Typography fontSize={13} fontWeight={700} color={grey[900]} sx={{pb: 0.5}}>Publish</Typography>
                  <Switch
                    checked={!!organization?.published}
                    onChange={() => onPublish(organization?.id)}
                  />
                </Box>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export const CreateOrganization = ({open, handleClose, selectedOrganization, onSuccess}) => {
  const [organization, setOrganization] = useState({name: "", organization_type: "", members: [], published: false});
  const [users, setUsers] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/user/all', null, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`
          }
        });
        const users = response.data?.map(u => u.username);
        setUsers(users);
      } catch (err) {
        toast.error("Failed to get users")
      }
    }
    fetchUsers();
  }, [])


  useEffect(() => {
    setOrganization({
      id: selectedOrganization?.id || null,
      name: selectedOrganization?.name || "",
      organization_type: selectedOrganization?.organization_type || "",
      published: selectedOrganization?.published || false,
      members: selectedOrganization?.members || []
    });
    // setImage(selectedOrganization?.img || null);
  }, [selectedOrganization])

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    onUpdate(key, value);
  }

  const onUpdate = (key, value) => {
    setOrganization(prev => ({...prev, [key]: value}))
  }

  const createImage = async () => {
    if (!image) {
      return;
    }
    const id = uuid();
    const url = `organizations/${id}-${image?.name}`;

    return await uploadImage({img: image, url: url});
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        sx: {minWidth: "320px", maxWidth: "540", width: "100%"},
        onSubmit: async (event) => {
          event.preventDefault();
          if (!organization?.name) {
            toast.error("Organization name is required!")
            return;
          }
          let img = null;
          if (image && typeof image === "object") {
            img = await createImage();
          }

          if (selectedOrganization?.id) {
            OrganizationRepository.edit(selectedOrganization?.id, {...organization, img})
              .then(response => {
                onSuccess();
                toast.success("Successfully updated organization");
                handleClose();
              })
              .catch(error => {
                toast.error(error?.message || "Failed to update organization");
              })
          } else {
            OrganizationRepository.create({...organization, img})
              .then(response => {
                onSuccess();
                toast.success("Successfully created organization");
                handleClose();
              })
              .catch(error => {
                toast.error(error?.message || "Failed to create organization");
              })
          }
        },
      }}
    >
      <DialogTitle>{selectedOrganization?.id ? "Update" : "Create"} Organization</DialogTitle>
      <DialogContent>
        {/*<DialogContentText fontSize={12}>*/}
        {/*  In natural language processing (NLP), a "knowledge base" refers to a structured repository of information that*/}
        {/*  is used to support various NLP tasks such as question answering, information retrieval, and natural language*/}
        {/*  understanding.*/}
        {/*</DialogContentText>*/}

        <Grid container>
          <Grid item xs={12} md={4} sx={{p: 1}}>
            <Stack direction={"column"} alignItems={"center"} gap={2}>
              <Box>
                <Avatar
                  src={image !== null ? typeof image === "string" ? image : URL.createObjectURL(image) : null}
                  alt={"Organization logo"}
                  sx={{
                    border: 1,
                    borderColor: "#153B50",
                    bgcolor: "transparent",
                    height: 120,
                    width: 120,
                    fontSize: 50,
                    borderRadius: 3,
                    mb: "auto", color: "#153B50"
                  }}
                >
                  {image ? null : <IconCamera size={50}/>}
                </Avatar>
              </Box>
              <Button
                component="label"
                fullWidth disabled
              >
                Upload File
                <input
                  type="file"
                  hidden
                  onChange={(event) => setImage(event.target.files[0])}
                  accept="image/*"
                />
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8} sx={{p: 1}}>
            <Stack direction={"column"} gap={2}>
              <TextField
                label={"Name"}
                required
                fullWidth
                name={"name"}
                value={organization?.name}
                onChange={onChange}
              />
              <TextField
                label={"Type"}
                required
                fullWidth
                name={"organization_type"}
                value={organization?.organization_type}
                onChange={onChange}
              />
              <FormControl>
                <InputLabel id={`users-label`}
                            sx={{textTransform: "capitalize"}}>Members</InputLabel>
                <Select
                  labelId={`users-label`}
                  id={`users-select`}
                  sx={{flex: 1}}
                  label={"Members"}
                  multiple
                  value={organization?.members || []}
                  onChange={(e) => {
                    const value = e.target.value || "";
                    let selected = typeof value === 'string' ? value.split(',') : value || [];
                    onUpdate("members", selected);
                  }}
                >
                  {users.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{px: 2, py: 1}}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </Dialog>
  )
}