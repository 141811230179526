import React, {useEffect, useState} from "react";
import {
  Fade, IconButton,
  Stack
} from "@mui/material";
import {Chat} from "../../../components/chat/Chat";
import {v4 as uuidv4} from "uuid"
import {IconX} from "@tabler/icons-react";

export const ChatDrawer = ({open, handleClose, project}) => {
  const [json, setJson] = useState(null);

  useEffect(() => {
    if (project) {
      const debugId = uuidv4();
      localStorage.setItem("debug", debugId);
      const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/apiCurrentProject/${project?.id}?debug=${debugId}`
      setJson(url);
    }
  }, [project])

  return (
    <Fade in={open} timeout={500}>

      <Stack direction={"column"} gap={1} sx={{
        display: open ? "flex" : "none",
        minWidth: "400px",
        maxWidth: "450px",
        width: "100% !important",
        bgcolor: "#FFF",
        overflowX: "hidden",
        borderLeft: 1,
        borderColor: "divider",
        position:'absolute',
        top:50,
        right:0,
        bottom:0,
        zIndex:100000
      }}>
        <IconButton
            sx={{position:'absolute', top:0, left:0,marginBottom:2}}
            onClick={handleClose}>
          <IconX />
        </IconButton>
        {json && open && <Chat json={json} project={project}/>}
      </Stack>
    </Fade>
  )
}
