import {Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers";
import {ASRIConversationsRepository} from "../../../../repositories/ASRIConversationsRepository";
import useWebSocket from "../../../../hooks/useWebSocket";

// Function to group conversations by date and status
const groupByDateAndStatus = (data, dateFormat, view) => {
  const groupedData = {};

  data?.forEach((conversation) => {
    let date;
    if (view === "daily") {
      date = moment(conversation.dateCreated).format(dateFormat);
    } else {
      const startOfWeek = moment(conversation.dateCreated).startOf('week').format(dateFormat);
      const endOfWeek = moment(conversation.dateCreated).endOf('week').format(dateFormat);
      date = `${startOfWeek} - ${endOfWeek}`;
    }

    if (!groupedData[date]) {
      groupedData[date] = {};
    }
    if (conversation.status !== "ACTIVE") {
      if (!groupedData[date][conversation.status]) {
        groupedData[date][conversation.status] = 0;
      }

      groupedData[date][conversation.status] += 1;
    }
  });

  return groupedData;
};

// Function to prepare chart data with multiple series based on status
const prepareChartData = (data, dateFormat, view) => {
  const groupedData = groupByDateAndStatus(data, dateFormat, view);
  const categories = Object.keys(groupedData).sort((a, b) => {
    // Parsing the dates with Moment.js
    const dateA = moment(a, 'DD MMM');
    const dateB = moment(b, 'DD MMM');

    // Sorting the dates
    return dateA.isAfter(dateB) ? 1 : -1;
  });

  const statuses = ["FINISHED", "REDIRECTED", "FAILED"];

  const seriesData = statuses.map(status => {
    return {
      name: status,
      data: categories.map(date => groupedData[date][status] || 0),
    };
  });

  return {
    categories,
    seriesData,
  };
};

export const Last30DaysStatistic = ({title, project}) => {
  const dateLastMonth = new Date();
  const dateNow = new Date()
  dateLastMonth.setTime(dateNow.setHours(0,0, 0, 0))
  dateNow.setTime(dateNow.setHours(0,0, 0, 0))
  const updatedValue = useWebSocket(`/topic/active_calls/${project?.settings?.speechToText}`);

  const [conversations, setConversations] = useState([]);
  const [startDate, setStartDate] = useState(moment(dateLastMonth.setMonth(dateNow.getMonth() - 1)));
  const [endDate, setEndDate] = useState(moment(dateNow));

  const fetchData = () => {
    const sessionId = project?.settings?.speechToText;
    const start = startDate.format('YYYY-MM-DDT00:00:00');
    const end = endDate === null ? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') : endDate.format('YYYY-MM-DDT23:59:59')
    ASRIConversationsRepository.getConversationsByDate({sessionId, startDate: start, endDate: end})
        .then(response => setConversations(response.data))
        .catch(error => console.log(error));
  }


  useEffect(() => {
    fetchData()
    if (project?.settings?.speechToText) {
      fetchData();

      const interval = setInterval(() => {
        fetchData();
      }, 1000 * 20)

      return () => clearInterval(interval)
    }
  }, [project, startDate, endDate]);

  useEffect(() => {
    fetchData()
  }, [updatedValue])

  const [view, setView] = useState("daily");

  const dateFormat = "DD MMM";
  const chartData = prepareChartData(conversations?.filter(conversation => conversation?.status !== "ACTIVE"), dateFormat, view);

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      }
      // stackType: "100%",
    },
    grid: {
      show: false,
    },
    xaxis: {
      show: false,
      categories: chartData.categories,
      labels: {
        show: true
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      },
    },
    fill: {
      opacity: 1
    },
    colors: [
      '#66DA26',
      '#FF9800',
      '#E91E63',
      '#2E93fA',
      '#546E7A'
    ],
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false,
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    legend: {
      show: false,
      position: 'bottom',
      offsetX: 0,
      offsetY: 50
    },
  };

  const series = chartData.seriesData;

  const isDaily = view === "daily";

  return (
    <Stack direction={"column"} gap={1} sx={{p: {xs: 2, md: 4}, pb: 0, borderRadius: 2, maxHeight: "400px"}} className={"border-shadow"}>
      <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"flex-start"} useFlexGap flexWrap={"wrap"}>
        <Typography variant={"h5"} fontWeight={600}>{title} {view === "daily" ? "days" : "weeks"}</Typography>

        {/*<Box>*/}
        {/*  <ButtonGroup color={"secondary"}>*/}
        {/*    <Button*/}
        {/*      sx={{*/}
        {/*        borderTopLeftRadius: "16px !important", borderBottomLeftRadius: "16px !important",*/}
        {/*        bgcolor: isDaily ? grey[300] : grey[100], fontWeight: 600, color: grey[700],*/}
        {/*        fontSize: 13,*/}
        {/*      }}*/}
        {/*      size={"small"}*/}
        {/*      onClick={() => setView("daily")}*/}
        {/*    >*/}
        {/*      Daily*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      sx={{*/}
        {/*        borderTopRightRadius: "16px !important", borderBottomRightRadius: "16px !important",*/}
        {/*        bgcolor: !isDaily ? grey[300] : grey[100], fontWeight: 600, color: grey[700],*/}
        {/*        fontSize: 13,*/}
        {/*      }}*/}
        {/*      size={"small"}*/}
        {/*      onClick={() => setView("weekly")}*/}
        {/*    >*/}
        {/*      Weekly*/}
        {/*    </Button>*/}
        {/*  </ButtonGroup>*/}
        {/*</Box>*/}
        <Stack direction={"row"} gap={2}>
          <DatePicker
            format={"DD MMM YYYY"} label={"From"}
            value={startDate} onChange={value => setStartDate(value)}
            slotProps={{
              textField: {
                variant: "standard", size: "small",
                sx: { minWidth: 0, maxWidth: "150px" }
              }
            }}
          />
          <DatePicker
            format={"DD MMM YYYY"} label={"To"}
            value={endDate} onChange={value => setEndDate(value)}
            slotProps={{
              textField: {
                variant: "standard", size: "small",
                sx: { minWidth: 0, maxWidth: "150px" }
              }
            }}
          />

        </Stack>
      </Stack>

      <Chart options={options} series={series} type="bar" height={270} />
    </Stack>
  );
};
