import React, {useEffect, useState} from "react";
import {
  Stack
} from "@mui/material";
import {IconAccessPoint, IconFileTypePdf, IconForms, IconNumbers, IconWorld} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import {OrganizationRepository} from "../../../repositories/OrganizationRepository";
import {TopicRepository} from "../../../repositories/TopicRepository";
import {SlotRepository} from "../../../repositories/SlotRepository";
import {SideMenuKB} from "./side-menu/SideMenuKB";
import {Route, Routes} from "react-router-dom";
import {KnowledgeTable} from "./knowledge-table/KnowledgeTable";
import {KnowledgeDocument} from "./knowledge-details/KnowledgeDocument";

export const ResourceType = {
  url: "URL",
  live: "Live data",
  pdf: "PDF document",
  onlinepdf: "PDF link",
  text: "Plain text",
  vector: "Vector",
}
export const ResourceTypeIcon = {
  "url": <IconWorld color={"#303c77"} size={18} />,
  "live": <IconAccessPoint color={"#303c77"} size={18} />,
  "vector": <IconNumbers color={"#303c77"} size={18} />,
  "pdf": <IconFileTypePdf color={"#b0151a"} size={18} />,
  "onlinepdf": <IconFileTypePdf color={"#b0151a"} size={18} />,
  "text": <IconForms color={grey[700]} size={18} />,
}

export const KnowledgeBase = ({project}) => {
  const [organization, setOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [topics, setTopics] = useState([]);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    const initData = async () => {
      try {
        const orgId = project?.settings?.organization;
        const results = await OrganizationRepository.getAll();
        setOrganizations(results?.data || []);
        const selected = results?.data?.find(org => org?.id == orgId);
        setOrganization(selected || null);

        const topicData = await TopicRepository.getAllByOrgId(orgId);
        setTopics(topicData?.data || []);
        const slotsData = await SlotRepository.getAllByOrganizationId(orgId);
        setSlots(slotsData?.data || []);
      } catch (err) {
        console.log(err);
      }
    }
    if (project) {
      initData();
    }
  }, [project])



  return (
    <Stack direction={"row"} sx={{maxHeight: "100%", height: "100%"}}>
      <SideMenuKB
        project={project} organization={organization}
        slots={slots} setSlots={setSlots}
        topics={topics} setTopics={setTopics}
      />
      <Routes>
        <Route path="/:knowledgeId" index  element={<KnowledgeDocument project={project} organization={organization} topics={topics} slots={slots} />} />
        <Route path="" index  element={<KnowledgeTable project={project} organization={organization} slots={slots} topics={topics}/>} />

      </Routes>
    </Stack>
  )
}