import React from "react";
import {TOKEN} from "../../../../context/SignInContext";
import {Stack, Typography} from "@mui/material";

export const Members = ({newProject}) => {

  return (
    <Stack direction={"column"} sx={{p: 2, height: "calc(100% - 20px) !important"}}>
      {
        newProject?.speechToText ?
          <iframe title={"Members"}
                  allow=""
                  src={`${process.env.REACT_APP_ASRI_GATEWAY}/cross-origin/project/${newProject?.speechToText}/members?auth=${localStorage.getItem(TOKEN)}`}
                  style={{width: "100%", height: "100%", overflowX: "hidden", flex: 1, border: 0}}
            // onLoad={handleLoad}
          >
          </iframe>
          :
          <Typography>Please select asri project...</Typography>
      }
    </Stack>
  )
}