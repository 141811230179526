import axios from "../config/axios-asri"

export const ASRIVoicebotPropertiesRepository = {

  // GET a specific voicebot property by id
  getPropertyById: (id) => {
    return axios.get(`/api/voicebot-properties/${id}`)
  },

  // POST (or PUT) to update a specific property by id
  updatePropertyById: (id, value) => {
    return axios.post(`/api/voicebot-properties/${id}`, value)
  },

  // GET all voicebot properties
  getAllProperties: () => {
    return axios.get(`/api/voicebot-properties`)
  },

  // POST or PUT to update multiple properties
  updateProperties: (properties) => {
    return axios.post(`/api/voicebot-properties`, properties)
  }
}
