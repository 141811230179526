import React, {useState} from "react";
import {
  Box,
  Button,
  Stack,
} from "@mui/material";
import {
  IconDeviceFloppy,
  IconTrash,
} from "@tabler/icons-react";
import {ProjectForm} from "../../../project-creation/components/ProjectForm";
import {SIPData} from "./SIPData";
import {Vocabulary} from "./Vocabulary";
import {Members} from "./Members";
import {VoicebotConfiguration} from "./VoicebotConfiguration";

const ViewTypes = {
  GENERAL: "general",
  SIP: "voice connector",
  VOCABULARY: "text to speech",
  MEMBERS: "members",
  CONFIGURATION: "configuration"
}

export const ProjectUpdateForm = ({
                                    project,
                                    setProject,
                                    languages,
                                    organizations,
                                    asriProjects,
                                    fetchData,
                                    loading,
                                    onDelete
                                  }) => {

  const [view, setView] = useState(ViewTypes.GENERAL)

  const onChange = e => {
    const key = e.target.name;
    const value = e.target.value;

    updateProject(key, value);
  }

  const updateProject = (key, value) => {
    setProject(prev => ({...prev, [key]: value}))
  }

  const buttons = <Stack direction={"row"} justifyContent={"space-between"} sx={{px: 3, pb: 3}}>
    <Button type={"button"} variant={"outlined"} color={"error"}
            onClick={onDelete} disabled={loading}
            sx={{borderRadius: "8px !important", border: "0.4px solid !important", borderColor: "error"}}
            startIcon={<IconTrash/>}>
      Delete
    </Button>
    <Button type={"submit"} variant={"contained"} disabled={loading}
            color={"success"} sx={{borderRadius: "8px !important", border: 1}}
            startIcon={<IconDeviceFloppy/>}>
      Save
    </Button>
  </Stack>

  return (
    <>
      <Stack direction={'row'} gap={2} sx={{pt: 2, px: 3}}>
        {
          Object.keys(ViewTypes)
            ?.map((key) => {
              const value = ViewTypes[key];
              return (
                <Button
                  color={view === value ? "primary" : "secondary"}
                  onClick={() => setView(value)}
                  sx={{fontWeight: view === value ? 600 : 400, fontSize: 16, textTransform: "capitalize"}}
                >
                  {value}
                </Button>
              )
            })
        }
      </Stack>
      <Box sx={{pb: 1, maxHeight: "calc(100dvh - 140px)", height: '100%'}}>
        {
          {
            [ViewTypes.GENERAL]: (
              <>
                <ProjectForm setProject={setProject} project={project} languages={languages}
                             organizations={organizations}
                             asriProjects={asriProjects} fetchData={fetchData}/>

                {buttons}
              </>

            ),
            [ViewTypes.SIP]: (
              <>
                <SIPData onChange={onChange} project={project}/>
                {buttons}

              </>
            ),
            [ViewTypes.VOCABULARY]:
              (
                <Vocabulary project={project} onChange={onChange}/>
              ),
            [ViewTypes.MEMBERS]:
              (
                <Members newProject={project} />
              ),
            [ViewTypes.CONFIGURATION]:
              (
                <VoicebotConfiguration newProject={project} />
              )
          }[view]
        }


      </Box>
    </>
  )
}
