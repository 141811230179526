import React, {Suspense, useContext, useEffect, useState} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {theme} from "./config/Theme";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import LoadingScreen from "./components/loading-screen/LoadingScreen";
import {LoginPage} from "./pages/auth/login/LoginPage";
import {Projects} from "./pages/projects/Projects";
import {SignInContext} from "./context/SignInContext";
import {ReactFlowProvider} from "reactflow";
import UnderMaintenance from "./pages/under-maintenance/UnderMaintenance";
import {ProjectHomePage} from "./pages/project/ProjectHomePage";
import {RegisterPage} from "./pages/auth/register/RegisterPage";
import {Organizations} from "./pages/organizations/Organizations";
import {OAuthSuccess} from "./pages/auth/login/OAuthSuccess";
import {ProjectCreationPage} from "./pages/project-creation/ProjectCreationPage";
import {MainLayout} from "./layouts/main-layout/MainLayout";
import {LicenseRepositoryUM} from "./repositories/LicenseRepositoryUM";
import LicenseExpired from "./components/maintenance/LicenseExpired";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

export const App = () => {
  const {isAuth} = useContext(SignInContext);
  const [licenseExpired, setLicenseExpired] = useState(false);

  useEffect(() => {
    checkLicense();
  }, []);

  const checkLicense = async () => {
    try {
      const responseLicense = await LicenseRepositoryUM.checkLicense();
      const isValid = responseLicense.data;
      if (!isValid) {
        toast.error("License Expired...")
        setLicenseExpired(true);
        return;
      }
      setLicenseExpired(false);
    } catch (e) {
      console.error(e);
      toast.error("License Expired...")
      setLicenseExpired(true);
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ReactFlowProvider>
          <ToastContainer theme={"colored"}/>
          <Suspense fallback={<LoadingScreen/>}>
            {
              licenseExpired ? <LicenseExpired/> : isAuth === null ? null
                : <Routes>
                  <Route path="/under-maintenance" exact index element={<UnderMaintenance/>}/>

                  {
                    !isAuth ?
                      <>
                        <Route path="/login" index element={<LoginPage/>}/>
                        <Route path="/register" index element={<RegisterPage/>}/>
                        <Route path="/oauth2/redirect" index element={<OAuthSuccess/>}/>
                        <Route path="*" element={<Navigate to="/login"/>}/>
                      </>
                      :
                      <>
                        <Route element={<MainLayout/>}>
                          <Route path="/" exact index element={<Projects/>}/>
                          <Route path="/organizations" exact index element={<Organizations/>}/>
                          <Route path="/projects" exact index element={<Projects/>}/>
                          <Route path="/project/create" exact index element={<ProjectCreationPage/>}/>
                        </Route>
                        <Route path="/project/edit/:projectId" exact index element={<ProjectCreationPage/>}/>
                        <Route path="/project/:projectId/*" element={<ProjectHomePage/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                      </>
                  }
                </Routes>
            }
          </Suspense>
        </ReactFlowProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
