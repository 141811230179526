import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow,
  TextField
} from "@mui/material";
import {IconPencil, IconPlayerPlay, IconPlayerStop, IconPlus, IconSearch, IconTrash} from "@tabler/icons-react";
import {useEffect, useState} from "react";
import {CreateVocabularyItem} from "./CreateVocabularyItem";
import {toast} from "react-toastify";
import moment from "moment";
import axios from "axios";

export const Vocabulary = ({project}) => {
  const [vocabulary, setVocabulary] = useState([]);
  const [addVocabulary, setAddVocabulary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");
  const [pronunciation, setPronunciation] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null); // State to track the current audio
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (project?.organization) {
      fetchData();
    }
  }, [project]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_SERVER}/vocabulary`,
        {
          params: {
            organizationId: project?.organization,
            _sort: 'updatedAt',
            _order: 'desc',
          }
        }
      );
      const data = response.data;
      setVocabulary(data.sort((a, b) => b?.updatedAt - a?.updatedAt));
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlay = async (phrase) => {
    try {
      if (loading) {
        return;
      }
      // Stop the current audio if it exists
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_ASRI_GATEWAY}/api/audio/synthesis`,
        { message: phrase },
        { responseType: 'blob' } // Set the response type to blob
      );

      const blob = response.data; // Access the blob directly from response.data
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);

      audio.onended = () => setCurrentAudio(null);
      audio.play();

      // Set the new audio as the current audio
      setCurrentAudio(audio);
    } catch (error) {
      console.log(error);
      toast.error("Failed to play audio");
    } finally {
      setLoading(false);
    }
  };

  const handleStop = () => {
    if (currentAudio) {
      currentAudio?.pause();
      setCurrentAudio(null);
    }
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_JSON_SERVER}/vocabulary/${id}`);
      toast.success("Successfully deleted");
      await fetchData();
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete!");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{px: 4, py: 0, minHeight: "45vh", maxHeight: "calc(100dvh - 160px)", overflowY: "auto"}}>
      {addVocabulary && <CreateVocabularyItem
        open={addVocabulary}
        handleClose={async () => {
          if (page === 0) {
            await fetchData();
          } else {
            setPage(0);
          }
          setAddVocabulary(false);
          setSelectedItem(null);
        }}
        organization={project?.organization} selectedItem={selectedItem}
      />}

      <Stack direction={"row"} gap={2} sx={{py: 3}}>
        <TextField
          label={"Test Phrase"} placeholder={"Test pronunciation"} disabled={loading || (currentAudio !== null && currentAudio?.paused === false)}
          value={pronunciation} onChange={e => setPronunciation(e.target.value)}
          sx={{minWidth: "280px", flex: 1}} multiline minRows={2}
          autoFocus
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // Prevent the default action (e.g., form submission) if necessary
              handlePlay(event.target.value);
            }
          }}
          InputLabelProps={{shrink: true}}
          InputProps={{
            endAdornment: (<IconButton disabled={loading || !pronunciation} onClick={() => currentAudio === null || currentAudio?.paused ? handlePlay(pronunciation) : handleStop()}>{currentAudio === null || currentAudio?.paused ? <IconPlayerPlay /> : <IconPlayerStop />}</IconButton>)
          }}
        />
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Input
          label={"Search"} placeholder={"Search for some phrase..."}
          startAdornment={<IconSearch style={{marginRight: 10}} />}
          value={search} onChange={e => setSearch(e.target.value)}
        />
      </Stack>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: 600}}>Phrase</TableCell>
              <TableCell sx={{fontWeight: 600}}>Pronunciation</TableCell>
              <TableCell sx={{fontWeight: 600}}>Date</TableCell>
              <TableCell align={"right"}>
                <Button startIcon={<IconPlus/>} onClick={() => setAddVocabulary(true)}>Add</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vocabulary?.length === 0 && <TableRow><TableCell colSpan={3}>Vocabulary is empty</TableCell></TableRow>}
            {vocabulary?.filter(item => item?.key?.toLowerCase()?.includes(search?.toLowerCase()) || item?.value?.toLowerCase()?.includes(search?.toLowerCase()))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
              const {id, key, value, updatedAt} = item;
              return (
                <TableRow key={id}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                  <TableCell>{updatedAt ? moment(updatedAt).format("DD.MM.YYYY hh:mm") : null}</TableCell>
                  <TableCell align={"right"} sx={{textAlign: "right"}}>
                    <Stack direction={"row"} gap={2} justifyContent={"flex-end"} alignItems={"center"}>
                      <IconButton
                        onClick={() => {
                          setPronunciation(value);
                          handlePlay(value);
                        }}
                      >
                        <IconPlayerPlay />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedItem(item);
                          setAddVocabulary(true);
                        }}
                      >
                        <IconPencil/>
                      </IconButton>
                      <IconButton
                        color={"error"}
                        onClick={() => handleDelete(id)}
                      >
                        <IconTrash/>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      <Stack fullWidth direction={"row"} justifyContent={"flex-end"}>
        <TablePagination
          sx={{borderBottom: 0}}
          count={vocabulary.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Box>
  );
};
