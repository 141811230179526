import {useContext, useEffect, useState} from "react";
import {TOKEN} from "../context/SignInContext";
import {Client} from "@stomp/stompjs";

const useWebSocket = (topic) => {
    // State and setters for debounced value
    // const sessionContext = useContext(SessionContext);
    const [updateValue, setUpdateValue] = useState(null);

    useEffect(() => {
        if (topic) {
            let client = null;
            // const session = sessionContext.session
            let onConnected = () => {
                console.log(`Connected to topic ${topic}`)
                client.subscribe(topic, function (msg) {
                    if (msg.body) {
                        var jsonBody = JSON.parse(msg.body);
                        if (jsonBody) {
                            setUpdateValue(jsonBody)
                        }
                    }
                });
            }

            let onDisconnected = () => {
                console.log("Disconnected!!")
            }

            // let SOCKET_URL = process.env.REACT_APP_WS_API_ASRI_GATEWAY;
            // const moduleType = session?.module?.moduleType;
            let SOCKET_URL = process.env.REACT_APP_WS_API_ASRI_GATEWAY;

            // if (moduleType === ModuleType.TEXT_TO_SPEECH) {
            //     SOCKET_URL = process.env.REACT_APP_TTSI_WS;
            // } else if (moduleType === ModuleType.TEXT_TRANSLATION) {
            //     SOCKET_URL = process.env.REACT_APP_NMT_WS;
            // }
            SOCKET_URL += `?jwt=${localStorage.getItem(TOKEN)}`;
            client = new Client({
                brokerURL: SOCKET_URL,
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
                onConnect: onConnected,
                onDisconnect: onDisconnected,
                onStompError: () => console.log(`Failed to connect to topic (Stomp error): ${topic}`),
                onWebSocketError: (e) => {
                    console.log(`Failed to connect to topic (web socket error): ${topic} ${e}`)
                }
            });

            client.activate();
        }

    }, [topic])

    return updateValue;
};

export default useWebSocket;