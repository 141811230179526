import {FlowLayout} from "../../layouts/flow-layout/FlowLayout";
import {useParams, Routes, Route, Navigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../config/firebase";
import {Project} from "./flow/Project";
import {ProjectSettings} from "./settings/ProjectSettings";
import {KnowledgeBase} from "./knowledge-base/KnowledgeBase";
import {FineTuning} from "./fine-tuning/FineTuning";
import {AsriPage} from "./asri/AsriPage";
import {SipPage} from "./sip/SipPage";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import {atom, useAtom} from "jotai"
import {Dashboard} from "@mui/icons-material";
import {DashboardPage} from "./dashboard/DashboardPage";
import {ConversationBuilder} from "../conversation-builder/ConversationBuilder";

export const ProjectAtom = atom(null)

export const ProjectHomePage = () => {
  const {projectId} = useParams();

  const [project, setProject] = useAtom(ProjectAtom);
  const [openChat, setOpenChat] = useState(false);


  useEffect(() => {
    const  fetchProject = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_JSON_SERVER}/projects/${projectId}`);
        const json = await response.json();
        setProject(json);
      } catch (err) {
        console.log(err);
      }
    }
    if (projectId) {
      fetchProject();
    }
  }, [projectId])

  return (
    <FlowLayout
      project={project}
      toggleChat={() => setOpenChat(!openChat)}
      openChat={openChat}
    >
      {
        project === null ? <LoadingScreen /> :
          <Routes>
            {/*<Route path="knowledge-base/*"  element={<KnowledgeBase project={project}/>} />*/}
            {/*<Route path={"fine-tuning/:modelId/*"}  element={<FineTuning project={project}/>} />*/}
            {/*<Route path={"fine-tuning/*"}  element={<FineTuning project={project}/>} />*/}
            <Route path="settings" element={<ProjectSettings />} />
            <Route path={"asri"}  element={<AsriPage />} />
            <Route path={"sip"}  element={<SipPage />} />
            {/*<Route path="designer"  element={<Project project={project} setProject={setProject} openChat={openChat} setOpenChat={setOpenChat} />} />*/}
            <Route path="designer"  element={<ConversationBuilder project={project} />} />
            <Route path={""}  element={<DashboardPage project={project} />} />
            <Route path="*" element={<Navigate to="dashboard"/>}/>
          </Routes>
      }
    </FlowLayout>
  )
}