import {Typography, Modal, Box, Stack, IconButton, Tooltip} from "@mui/material";
import React from "react";
import {
  IconArrowsExchange,
  IconPhoneCalling,
  IconPhoneCheck,
  IconPhoneOutgoing,
  IconPhoneX,
  IconRefresh,
  IconReload,
  IconX
} from "@tabler/icons-react";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: {xs: "90vw", sm: "80vw", md: "60vw", lg: "50vw"},
  maxHeight: {xs: "90vh", md: "80vh"}, minHeight: "80vh",
  width: "90%",
  bgcolor: 'background.paper',
  borderRadius: 4,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};

export const ViewConversationModal = ({open, handleClose, conversation}) => {

  console.log(conversation);
  return (
    open && <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Stack direction={"column"} gap={2} sx={style} alignItems={"stretch"}>
        <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {
              {
                "ACTIVE": (
                  <Tooltip title="Call In Progress">
                    <IconPhoneCalling className={"ringing"} stroke={1} color={"green"} size={22}/>
                  </Tooltip>
                ),
                "FINISHED": (
                  <Tooltip title="Completed">
                    <IconPhoneCheck stroke={1} color={"green"} size={22}/>
                  </Tooltip>
                ),
                "REDIRECTED": (
                  <Tooltip title="Redirected to agent">
                    <IconPhoneOutgoing tool stroke={1} color={"red"} size={22}/>
                  </Tooltip>
                ),
                "FAILED": (
                  <Tooltip title="Failed">
                    <IconPhoneX stroke={1} color={"red"} size={22}/>
                  </Tooltip>
                ),
                "TERMINATED": (
                  <Tooltip title="Terminated">
                    <IconPhoneX stroke={1} color={"red"} size={22}/>
                  </Tooltip>
                ),
              }[conversation.status]
            }

            <Typography variant={"h4"} fontWeight={600}>{conversation?.msisdn}</Typography>
            {conversation?.vdnTransfer ? <Tooltip title={`VDN Transfer: ${conversation?.vdnTransfer}`}><IconArrowsExchange size={20} /></Tooltip> : null}
          </Stack>
          <IconButton onClick={handleClose} sx={{borderRadius: "50% !important"}}>
            <IconX />
          </IconButton>
        </Stack>
        <Stack dierction={"column"} sx={{flex: 1, overflowY: "auto"}}>
          <iframe
            title={"Display conversation"}
            style={{minWidth: "320px", width: '100%', flex: 1, border: 0}}
            src={`${process.env.REACT_APP_CHATBOT}/conversation/${conversation?.id}/view`}
          />
        </Stack>
      </Stack>
    </Modal>
  )
}