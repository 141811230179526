import axios from 'axios';
import {TOKEN} from "../context/SignInContext";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ASRI_GATEWAY}`,
  headers: {
    "Content-Type": "application/json"
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem(TOKEN);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
