import {Box, Stack, Typography} from "@mui/material";
import {Handle, Position} from "reactflow";
import {IconCircle} from "@tabler/icons-react";
import React, {memo} from "react";
import {green} from "@mui/material/colors";

function StartNode({id, data}) {

  return (
    <Box sx={{width: "100%", minWidth: "80px", bgcolor: green[500], borderRadius: 2, border: 1, borderColor: "white"}} className={"border-shadow"}>
      {/*<Handle type="target" position={Position.Left} id={id} />*/}
      <div className="custom-node__header">
        <Stack direction={"row"} gap={0.5} sx={{color: "#fefefe"}} justifyContent={"space-around"} alignItems={"center"}>
          <IconCircle size={13} strokeWidth={2} />
          <Typography sx={{fontSize: 11, fontWeight: "bold"}}>Start</Typography>
        </Stack>
      </div>
      <div className="custom-node__body" style={{background: "#FFF"}}>
        <Handle type="source" position={Position.Right} id={id} style={{marginLeft: "5px"}} />
      </div>
    </Box>
  )
}

export default memo(StartNode);
