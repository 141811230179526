import {
  Box,
  Button,
  Divider,
  IconButton, Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow, Tooltip,
  Typography
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {
  IconCircleFilled,
  IconX,
  IconPhone,
  IconPhoneCall,
  IconPhoneOff,
  IconPhoneOutgoing,
  IconPhoneX,
  IconPhoneCheck,
  IconPhoneCalling
} from "@tabler/icons-react";
import {ViewConversationModal} from "./ViewConversationModal";
import {DashboardCard} from "./DashboardCard";
import moment from "moment";
import {TransitionGroup} from "react-transition-group";
import {ASRIConversationsRepository} from "../../../../repositories/ASRIConversationsRepository";
import useWebSocket from "../../../../hooks/useWebSocket";
import './css/ActiveCalls.css'

export const ActiveCallsView = ({sessionId, activeConversations}) => {
  const [selectConversation, setSelectConversation] = useState(null);
  const [conversations, setConversations] = useState([])
  const [pagination, setPagination] = useState({
    page: 0, size: 10, totalElements: 0
  })

  const updatedValue = useWebSocket(`/topic/active_calls/${sessionId}`);


  // const containerRef = useRef();

  const fetchActiveConversations = () => {
    ASRIConversationsRepository.getConversationsPaged(pagination.page, pagination.size)
      .then(response => {
        setConversations(response.data.content || [])
        setPagination(prev => ({...prev, totalElements: response.data.totalElements}))
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    fetchActiveConversations()
  }, [pagination.page, pagination.size, updatedValue])

  // useEffect(() => {
  //   if (updatedValue && conversations?.length > 0) {
  //     setConversations(prev => {
  //       const state = [...prev];
  //       const index = state.findIndex(i => i?.id === updatedValue?.id);
  //       if (index >= 0) {
  //         state[index] = updatedValue;
  //       } else {
  //         state.unshift(updatedValue);
  //       }
  //       return state;
  //     })
  //   }
  // }, [updatedValue, conversations])

  return (
    <>
      <ViewConversationModal
        open={!!selectConversation}
        handleClose={() => setSelectConversation(null)}
        conversation={selectConversation}
      />

      <Stack direction={"column"}
             sx={{p: {xs: 1, md: 1}, borderRadius: 2, flex: 1, maxHeight: "calc(400px - 16px)"}}
             className={"border-shadow"}>

        <Box sx={{flex: 1, overflowY: "scroll"}}>

          <Table stickyHeader sx={{p: 1}} size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    fontSize: "0.8rem",
                    textAlign: "left",
                    pl: 1,
                    py: 1
                  }}>ID</TableCell>
                <TableCell sx={{fontWeight: 600, fontSize: "0.8rem"}}>MSISDN</TableCell>
                <TableCell sx={{fontWeight: 600, fontSize: "0.8rem"}}>ANSWERED</TableCell>
                <TableCell sx={{fontWeight: 600, fontSize: "0.8rem"}}></TableCell>
              </TableRow>
            </TableHead>
            <TransitionGroup
              transitionName="fade"
              transitionEnterTimeout={1000}
              transitionLeaveTimeout={300}
              transitionAppearTimeout={1000}
              transitionAppear={true}
              component={null}>
              <TableBody>
                {conversations.length > 0 && conversations?.map(conversation => {
                  return (
                    <TableRow sx={{"p": 0, height: '10px'}} key={conversation?.id}
                              hover>
                      <TableCell sx={{fontSize: "0.8rem", fontWeight: 300, "p": 0}}>
                        <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                          <Box sx={{color: conversation.status === "ACTIVE" ? "green" : "red", pt: 0.5}}>
                            <IconCircleFilled size={13}/>
                          </Box>
                          <Typography>{conversation?.id}</Typography>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{fontSize: "0.8rem", fontWeight: 300}}>
                        <Stack direction={"row"} gap={0.2}>
                          {
                            {
                              "ACTIVE": (
                                <Tooltip title="Call In Progress">
                                  <IconPhoneCalling className={"ringing"} stroke={1} color={"green"} size={18}/>
                                </Tooltip>
                              ),
                              "FINISHED": (
                                <Tooltip title="Completed">
                                  <IconPhoneCheck stroke={1} color={"green"} size={18}/>
                                </Tooltip>
                              ),
                              "REDIRECTED": (
                                <Tooltip title="Redirected to agent">
                                  <IconPhoneOutgoing tool stroke={1} color={"red"} size={18}/>
                                </Tooltip>
                              ),
                              "FAILED": (
                                <Tooltip title="Failed">
                                  <IconPhoneX stroke={1} color={"red"} size={18}/>
                                </Tooltip>
                              ),
                              "TERMINATED": (
                                <Tooltip title="Terminated">
                                  <IconPhoneX stroke={1} color={"red"} size={18}/>
                                </Tooltip>
                              ),
                            }[conversation.status]
                          }

                          <Typography>{conversation?.msisdn}</Typography>
                        </Stack>


                      </TableCell>
                      <TableCell sx={{fontSize: "0.8rem", fontWeight: 300}}>
                        <Stack direction={"row"} gap={0.2}>
                          <Typography
                            fontSize={"0.7rem"}>{moment(conversation?.dateTimeInit).fromNow()}</Typography>
                        </Stack>
                      </TableCell>

                      <TableCell
                        sx={{
                          fontSize: "0.8rem",
                          fontWeight: 400,
                          textAlign: "end"
                        }}>
                        <Button variant={"outlined"}
                                onClick={() => setSelectConversation(conversation)}>
                          Open
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })
                }
              </TableBody>
            </TransitionGroup>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={pagination?.totalElements}
          page={pagination?.page}
          // onPageChange={(e, page) => console.log(pagination.page, page)}
          onPageChange={(e, page) => setPagination(prev => ({...prev, page: page}))}
          rowsPerPage={pagination?.size}
          onRowsPerPageChange={(e) => setPagination(prev => ({...prev, page: 1, size: +e.target.value}))}
        />
      </Stack>
    </>
  )
}