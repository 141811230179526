import axios from "../config/axios-kb"


export const CategoryRepository = {
  getAll: () => {
    return axios.get(`/categories`);
  },
  findCategory: (id) => {
    return axios.get(`/categories/${id}`);
  },
  getCategoriesByMember: (id) => {
    return axios.get(`/categories/member/${id}`);
  },
  delete: (id) => {
    return axios.delete(`/categories/${id}`)
  },
  create: (data) => {
    return axios.post('/categories', data)
  },
  edit: (id, data) => {
    return axios.put(`/categories/${id}`, data)
  }
}