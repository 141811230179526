import {IconRobot} from "@tabler/icons-react";
import {DashboardCard} from "./DashboardCard";

export const ChatBotResponseTime = ({responseTime, conversations}) => {
  return (
    <DashboardCard
      count={responseTime > 0 ? (responseTime / 1000)?.toFixed(2) : 0}
      title={"ChatBot (s)"}
      icon={IconRobot}
      color={"#bb2e33"}
      description={"RESPONSE TIME"}
      chartSeries={conversations?.map(item => ({id: item?.conversationId, dateCreated: item?.dateCreated, value: (item?.averageValue / 1000)?.toFixed(2)})) || []}
    />
  );
}

